import { configureStore } from '@reduxjs/toolkit'
import { authSlice, cashOutSlice, multiplierSlice, userSlice, alertSlice } from './slices'

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    cashOut: cashOutSlice.reducer,
    user: userSlice.reducer,
    multiplier: multiplierSlice.reducer,
    alert: alertSlice.reducer,
  },
})
