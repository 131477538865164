import { Grid, Typography } from '@mui/material'

const CoinbaseTitle = (props: any) => {
  return (
    <Grid item xs={12}>
      <Typography color="#0467D7" sx={{ fontWeight: 700, fontSize: '26px', marginBottom: '4px' }}>
        {props.selectedCashoutPlatform && props.selectedCashoutPlatform.toLowerCase() === 'coinbase'
          ? props.selectedCashoutPlatform.toLowerCase()
          : props.selectedCashoutPlatform}
      </Typography>
    </Grid>
  )
}

export default CoinbaseTitle
