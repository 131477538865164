/**
 * Attempts to reload the page by utilizing a lastReloadTime timestamp.
 * This is to make sure we don't spam the reload() function causing epilepsy :)
 */

// Number of minutes that needs to pass since the last reload
const NUM_MINUTES = 5

/**
 * Checks whether we can reload or not, and handles it accordingly.
 * If we can reload, then it records the current timestamp to use for future attempts.
 */
export function tryReloadPage() {
  const lastReloadTime = localStorage.getItem('lastReloadTime')

  if (!lastReloadTime || shouldReload(lastReloadTime)) {
    const currentTime = new Date().toISOString()
    localStorage.setItem('lastReloadTime', currentTime)

    // Reload after a small delay of 5 seconds
    setTimeout(() => {
      window.location.reload()
    }, 5000)
  }
}

/**
 * Compares the lastReloadTime and the currentTime to determine if we should reload.
 * Uses the NUM_MINUTES constant defined at the top for the elapsed time.
 * @param {string} lastReloadTime The timestamp of the last reload attempt.
 * @returns {boolean} True if enough time has passed since the last reload, otherwise false.
 */
function shouldReload(lastReloadTime: string) {
  const currentTimestamp = new Date().getTime()
  const lastTimestamp = new Date(lastReloadTime).getTime()
  const timeDifference = currentTimestamp - lastTimestamp
  return timeDifference >= NUM_MINUTES * 60 * 1000
}
