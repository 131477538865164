import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'

const Disclaimer = () => {
  const { durationDays, tokenExpiresDate, tokenExpiresTime } = useSelector((state: RootState) => state.multiplier)

  return (
    <Grid
      item
      xs={12}
      sx={{
        paddingTop: '68px',
        paddingBottom: '35px',
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#FFFFFF',
          fontWeight: 900,
          fontSize: { xxs: '20px', sm: '20px' },
        }}
      >
        DISCLAIMER:
      </Typography>
      <Box sx={{ paddingLeft: { xxs: '5%', sm: '105px', md: '160px' }, paddingRight: { xxs: '5%', sm: '105px', md: '160px' } }}>
        <Typography
          sx={{ textAlign: 'center', color: '#FFFFFF', fontWeight: 500, fontSize: { xxs: '0.8rem', xs: '1rem', md: '1.2rem' } }}
        >
          The multiplier is only valid for the account linked to this email. Amount of Bling Points will vary. Your multiplier
          will end {durationDays} days from the moment you activate. This exclusive Bling Point multiplier offer expires{' '}
          {tokenExpiresTime} PST on {tokenExpiresDate}.
        </Typography>
      </Box>
    </Grid>
  )
}

export default Disclaimer
