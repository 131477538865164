import { ListItem, ListItemIcon, Typography, ListItemText, Box, Grid, List, FormControl, MenuItem, Select } from '@mui/material'
import { useState } from 'react'

const WALLET_INSTRUCTIONS: Record<string, Array<string>> = {
  amber: [
    'Open AmberApp on your mobile device',
    'Tap the blue circle icon on the bottom right corner containing the Bitcoin symbol',
    'Tap the "Receive" option',
    'Tap the QR code icon on the top right corner',
    "If you're reading this from a mobile device, copy your LNURL provided below and paste it into the wallet using their paste button. If you are using a different device, tap the QR code icon below to reveal the QR code, then scan it with the wallet.",
    "You should see a success message with the number of SATS you've received",
  ],
  zebedee: [
    'Open ZBD on your mobile device',
    'Tap the green box on the top right',
    'Tap the "Scan QR Code" button centered at the bottom of the popup',
    "If you're reading this from a mobile device, copy your LNURL provided below and paste it into the wallet using their paste button. If you are using a different device, tap the QR code icon below to reveal the QR code, then scan it with the wallet.",
    'Tap "Confirm"',
    'You should see a success message "Request Sent"',
  ],
}

const Instruction = ({ number, text }: { number: number; text: string }) => (
  // Circle icon and text don't align perfectly which looks completely off for one line instructions
  <ListItem alignItems={text.length <= 34 ? undefined : 'flex-start'}>
    <ListItemIcon sx={{ display: 'flex', alignItems: 'center', minWidth: '35px' }}>
      <Typography
        variant="body2"
        sx={{
          borderRadius: '50%',
          width: 24,
          height: 24,
          bgcolor: 'white',
          color: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #E8E8E8',
          fontWeight: 500,
        }}
      >
        {number}
      </Typography>
    </ListItemIcon>
    <ListItemText sx={{ marginTop: text.length <= 34 ? undefined : '7px' }}>
      <Typography variant="body2">{text}</Typography>
    </ListItemText>
  </ListItem>
)

const WalletInstructions = ({ selectedWallet }: { selectedWallet: string }) => {
  if (selectedWallet === '') return null

  const instructions = WALLET_INSTRUCTIONS[selectedWallet.toLowerCase()]

  return (
    <Grid item xs={12}>
      <Typography align="center" sx={{ mt: '15px', fontSize: '14px' }}>
        Follow the instructions below to withdraw.
      </Typography>
      <Box sx={{ bgcolor: '#f2f6fa', borderRadius: 1, mt: 2 }}>
        <List>
          {instructions.map((instruction, index) => (
            <Instruction key={index} number={index + 1} text={instruction} />
          ))}
        </List>
      </Box>
    </Grid>
  )
}

export const WalletDropdown = () => {
  const [selectedWallet, setSelectedWallet] = useState<string>('')

  return (
    <Grid container>
      <FormControl fullWidth sx={{ m: 1 }}>
        <Select
          value={selectedWallet}
          onChange={(event) => setSelectedWallet(event.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">Select a wallet</MenuItem>
          <MenuItem value="AMBER">AmberApp</MenuItem>
          <MenuItem value="ZEBEDEE">ZBD</MenuItem>
        </Select>
      </FormControl>

      <WalletInstructions selectedWallet={selectedWallet} />
    </Grid>
  )
}
