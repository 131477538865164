import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import BlingPointsCoin from './BlingPointsCoin'

/**
 * Helper function to format a number with separators (e.g. 1 000 000)
 * @param balance The user's balance
 * @param separator The separator to use (e.g. ',')
 * @returns The formatted balance string
 */
export const formatNumberWithSeparators = (balance: number, separator: string): string => {
  return balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

const BlingPointsValue = () => {
  const { blingPoints } = useSelector((state: RootState) => state.user)

  return (
    <Grid item xs={12} display="flex" paddingTop="12px">
      <BlingPointsCoin />
      <Typography
        sx={{
          color: ' #FFFFFF',
          paddingLeft: '8px',
          fontSize: { xxs: '35px', xs: '50px', sm: '54px' },
          fontWeight: 900,
          font: 'normal Proxima Nova',
        }}
      >
        {formatNumberWithSeparators(blingPoints, ' ')}
      </Typography>
    </Grid>
  )
}

export default BlingPointsValue
