/**
 * URL Assets
 */
export const BLING_LOGO_WHITE =
  'https://uploads-ssl.webflow.com/5e56cc02668b33bc0ee8710e/624e0d2285511b4833f7dde7_Bling%20-%20white.svg'
export const BLING_LOGO_BLUE =
  'https://uploads-ssl.webflow.com/5e56cc02668b33bc0ee8710e/613a55d1eafa4967cc9f848d_Bling%20-%20Color.svg'
export const BLING_LOGO_BLUE_FULL =
  'https://uploads-ssl.webflow.com/5e56cc02668b33bc0ee8710e/61538aa25d8e29743f5042fb_Bling%20Financial%20-%20Color.svg'
export const BLING_POINTS_COIN =
  'https://uploads-ssl.webflow.com/5e56cc02668b33bc0ee8710e/5f6a88c7ac47254478f8c055_BlingPoint-01.svg'
export const BLING_MULTIPLE_COINS =
  'https://uploads-ssl.webflow.com/5e56cc02668b33bc0ee8710e/60f86a697d4fe2422de13ab8_multiple%20coin.svg'
export const MULTIPLIER_BLING_MULTIPLE_COINS =
  'https://uploads-ssl.webflow.com/5e56cc02668b33bc0ee8710e/62f5654d6023695c9bea6770_Artboard-21%4010x.webp'
export const MULTIPLIER_VALUE_BACKGROUND =
  'https://uploads-ssl.webflow.com/5e56cc02668b33bc0ee8710e/62e46ef3e24be834b4bebde6_Multiplicator-back.webp'
export const BLING_CROWN = 'https://uploads-ssl.webflow.com/5e56cc02668b33bc0ee8710e/62e1a172d1e7bdfd60cec080_Crown.png'

export const GAME_ICONS = {
  BITCOIN_SUDOKU:
    'https://firebasestorage.googleapis.com/v0/b/loaded-93189.appspot.com/o/FCMImages%2FSudoku%20icons%20(1).png?alt=media&token=c6526804-84de-4b42-a514-b77963aa172b',
  WORD_BREEZE:
    'https://firebasestorage.googleapis.com/v0/b/loaded-93189.appspot.com/o/FCMImages%2FWord%20breeze.png?alt=media&token=a6930d86-0e1e-4b71-8de4-f5b6bd7a8e90',
  SWEET_BITCOIN:
    'https://firebasestorage.googleapis.com/v0/b/loaded-93189.appspot.com/o/FCMImages%2FSweet-BTC.png?alt=media&token=e25ce75e-fdc4-4eb7-9cf5-22ca2cf86d4c',
  BITCOIN_BLAST:
    'https://firebasestorage.googleapis.com/v0/b/loaded-93189.appspot.com/o/FCMImages%2Fbitcoin_blast_icon.png?alt=media&token=0cb07346-04fe-45c1-a0fd-be4936cb42a6',
  BITCOIN_BLOCKS:
    'https://firebasestorage.googleapis.com/v0/b/loaded-93189.appspot.com/o/FCMImages%2Fbitcoin_blocks_icon.png?alt=media&token=e3a739e0-869b-479b-9cba-b6f0ce84601b',
  BITCOIN_FOOD_FIGHT:
    'https://firebasestorage.googleapis.com/v0/b/loaded-93189.appspot.com/o/FCMImages%2Fbitcoin_foodfight_icon.png?alt=media&token=c5f07266-98fa-4436-83ce-b09a763e23a8',
  BITCOIN_POP:
    'https://firebasestorage.googleapis.com/v0/b/loaded-93189.appspot.com/o/FCMImages%2Fbitcoin_pop_icon.png?alt=media&token=c61029b9-26da-4c53-80ce-32925ad5dde1',
  BITCOIN_SOLITAIRE:
    'https://firebasestorage.googleapis.com/v0/b/loaded-93189.appspot.com/o/FCMImages%2Fbitcoin_solitaire_icon.png?alt=media&token=9324d111-5079-4600-8e92-061c8d211b9e',
  ETHEREUM_BLAST:
    'https://firebasestorage.googleapis.com/v0/b/loaded-93189.appspot.com/o/FCMImages%2F01nani.png?alt=media&token=653c6aaf-cd25-4b1a-83d6-3a4af2dc8a9d',
}

/**
 * Federated Auth Providers used by Firebase Auth
 */
export const PROVIDERS = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  APPLE: 'apple',
}

/**
 * Cash out responses directly from Bling Cloud Functions constants
 */
export const CASHOUT_ACTION_TYPE = {
  CASHOUT_EMAIL_CONFIRMATION: 'cashoutEmailConfirmation',
  CASHOUT_EMAIL_VERIFICATION: 'cashoutEmailVerification',
  BLING_EMAIL_CHANGE_CONFIRMATION: 'blingEmailChangeConfirmation',
  BLING_EMAIL_CHANGE_VERIFICATION: 'blingEmailChangeVerification',
  BLING_EMAIL_VERIFICATION: 'blingEmailVerification',
  COUNTRY_VERIFICATION: 'countryVerification',
  CASHOUT_CREATED: 'cashoutCreated',
  CODE_WRONG_RETRY: 'codeWrongRetry',
  RETRIES_EXHAUSTED: 'retriesExhausted',
  INVALID_EMAIL: 'invalidEmail',
  UNAVAILABLE: 'unavailable',
  UNAVAILABLE_TOO_SOON: 'tooSoon',
  UNAVAILABLE_BELOW_MINIMUM_POINTS: 'belowMinimumPoints',
  UNAVAILABLE_EMAIL_TOO_SOON: 'emailTooSoon',
  UNAVAILABLE_EMAIL_ALREADY_USED: 'emailAlreadyUsed',
  UNAVAILABLE_EMAIL_ACCOUNT_CLOSED: 'emailAccountClosed',
  INTERNAL_ERROR: 'internalError',
}

/**
 * Cash out responses directly from Bling Cloud Functions constants
 */
export const CASHOUT_UNAVAILABLE_CODE = {
  NONE: 'NONE',
  NO_AUTH: 'NO_AUTH',
  TOO_SOON: 'TOO_SOON',
  BELOW_MINIMUM_POINTS: 'BELOW_MINIMUM_POINTS',
  ACCOUNT_PENDING_REVIEW: 'ACCOUNT_PENDING_REVIEW',
}

/**
 * Cash out status
 */
export const CASHOUT_STATUS = {
  PENDING: 'PENDING', //cash out record just created
  PENDING_LIGHTNING: 'PENDING_LIGHTNING', //cash out record just created for lightning - user must complete in wallet
  PROCESSED: 'PROCESSED', //cash out record paid out
}

/**
 * Core url routes
 * e.g. account.blingfi.com/login
 */
export const CORE_PATHS = {
  EMPTY: '',
  CASH_OUT: 'cash-out',
  LOGIN: 'login',
  MULTIPLIER: 'multiplier',
}

/**
 * Cash out routes
 */
export const CASH_OUT_PATHS = {
  CURRENCY_TYPE: 'currency-type', // TODO: Update to just 'currency'
  COINBASE: 'coinbase', // TODO: Update to 'coinbase-selection'?
  MINIMUM_POINTS: 'min-points', // TODO: Update to 'minimum-points'
  LAST_CASH_OUT: 'latest',
  PROCESS: 'process',
  VERIFICATION: 'verification',
  SUCCESS: 'success',
}

export const PLATFORM = 'Web'
export const GUID_COOKIE_NAME = 'guid'
