/**
 * Logout button in the NavBar
 */

import { Grid, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { logout } from '../../../redux/slices/auth'
import { AppDispatch } from '../../../redux/store'

const Logout = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()

  const logoutClickHandler = () => {
    dispatch(logout())
  }

  return (
    <Grid
      item
      xs={12}
      textAlign="right"
      sx={{
        color: props.color || 'white',
        fontWeight: 700,
        marginTop: '18px',
        marginLeft: { xxs: '70px' },
        marginRight: '15px',
      }}
    >
      <Button
        color="inherit"
        onClick={logoutClickHandler}
        sx={{ fontSize: { xxs: '10px', sm: '15px' }, fontWeight: 900, border: '2px solid' }}
      >
        Sign Out
      </Button>
    </Grid>
  )
}

export default Logout
