import { Button } from '@mui/material'

/**
 * The submit button UI that will submit the user's selected country to the backend
 * @param props
 * @constructor
 */
const SubmitCountryButton = (props: any) => {
  return (
    <Button
      variant="contained"
      type="submit"
      sx={{
        width: '100%',
        height: '50px',
        fontWeight: 800,
        background: 'transparent linear-gradient(180deg, #2CD0E9 0%, #006CFF 100%) 0% 0% no-repeat padding-box;',
        borderRadius: '8px',
        color: 'white',
        '&.Mui-disabled': {
          color: 'gray',
        },
        marginTop: '4px',
        fontSize: '16px',
      }}
      onClick={(e) => props.onSubmit(e)}
    >
      SUBMIT
    </Button>
  )
}

export default SubmitCountryButton
