import { Grid } from '@mui/material'

const BottomLayout = (props: any) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        paddingTop: props.paddingTop || '80px',
        width: '100%',
      }}
    >
      {props.children}
    </Grid>
  )
}

export default BottomLayout
