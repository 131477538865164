/**
 * Used to avoid emtpy React Fragments being used everywhere
 */

import { Props } from '../../types'

const Layout = ({ children }: Props) => {
  return <>{children}</>
}

export default Layout
