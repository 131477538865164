import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const ConversionType = (props: any) => {
  const { selectedCurrency, selectedCashoutPlatform } = useSelector((state: RootState) => state.cashOut)

  if (selectedCashoutPlatform === 'PAYPAL') {
    return (
      <Grid item xs={12}>
        <Typography
          align="center"
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            color: '#808285',
            textTransform: 'uppercase',
            opacity: 1,
            letterSpacing: 0,
          }}
        >
          U.S. DOLLARS
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Typography
        align="center"
        sx={{
          fontSize: '12px',
          fontWeight: 400,
          color: '#808285',
          textTransform: 'uppercase',
          opacity: 1,
          letterSpacing: 0,
        }}
      >
        {/* After a cash out we default to the Bitcoin value */}
        {selectedCurrency && selectedCurrency.name && selectedCurrency.name.toUpperCase() !== 'BITCOIN'
          ? 'CONVERSION RATE MAY VARY'
          : 'BITCOIN'}
      </Typography>
    </Grid>
  )
}

export default ConversionType
