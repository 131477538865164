/**
 * Layout for the actual unique multiplier content.
 */
import { Grid } from '@mui/material'
import { Props } from '../../types'

const Content = ({ children }: Props) => {
  return (
    <Grid container sx={{ minWidth: '250px', maxWidth: '1366px' }}>
      {children}
    </Grid>
  )
}

export default Content
