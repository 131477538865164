/**
 * Displays the user's current Bling Points and cryptocurrency conversion
 */

import TopLayout from '../../layouts/CashOut/TopLayout'
import ConversionCard from '../../components/CashOut/Templates/Card/ConversionCard'
import BlingPointsText from '../../components/CashOut/BlingPoints/BlingPointsText'
import BlingPointsValue from '../../components/CashOut/BlingPoints/BlingPointsValue'
import ConversionText from '../../components/CashOut/Conversion/ConversionText'
import ConversionType from '../../components/CashOut/Conversion/ConversionType'
import ConversionValue from '../../components/CashOut/Conversion/ConversionValue'
import NavBar from '../../components/CashOut/Navbar/NavBar'

const User = (props: any) => {
  return (
    <TopLayout>
      <NavBar />
      <BlingPointsText />
      <BlingPointsValue />

      <ConversionCard>
        <ConversionText />
        <ConversionValue />
        <ConversionType />
      </ConversionCard>
    </TopLayout>
  )
}

export default User
