import TitleText from './TitleSection/TitleText'
import Expires from './TitleSection/Expires'
import Value from './TitleSection/Circle/Value'
import BlingCrown from './TitleSection/Circle/BlingCrown'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import Circle from './TitleSection/Circle/Circle'
import MultiplierButton from './MultiplierButton'

const Title = (props: any) => {
  const { multiplier, decimalMultiplier, durationDays } = useSelector((state: RootState) => state.multiplier)

  return (
    <>
      {/* MEDIUM AND ABOVE */}
      <Box sx={{ display: { xxs: 'none', md: 'flex', zIndex: 1, paddingTop: '84px', paddingRight: '100px' } }}>
        {/* Title Section */}
        <Grid
          item
          xs={7}
          sx={{
            paddingLeft: { sm: '70px' },
            paddingRight: { lg: '25px' },
          }}
        >
          <TitleText gteMedium={true}>
            {multiplier}x FOR {durationDays} DAYS JUST FOR YOU!
          </TitleText>
          <Expires />

          <MultiplierButton onClick={props.onClick} />
        </Grid>

        {/* Multiplier Value Section */}
        <Grid item xs={5} marginLeft="100px">
          <Circle />
          <BlingCrown />
          <Box sx={{ position: 'absolute', marginTop: '-330px', marginLeft: '70px' }}>
            <Value gteMediumValue={true}>{decimalMultiplier ? `${Math.floor(multiplier)}` : multiplier}x</Value>
          </Box>
        </Grid>
      </Box>

      {/* SMALL AND BELOW */}
      <Grid
        container
        sx={{
          display: { xxs: 'flex', md: 'none' },
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
          paddingTop: '20px',
        }}
      >
        <Grid item>
          <Circle />
        </Grid>
        <Grid item>
          <BlingCrown />
        </Grid>
        <Grid item>
          <Box
            sx={{ position: 'absolute', marginLeft: { xxs: '-157px', sm: '-235px' }, marginTop: { xxs: '-60px', sm: '-95px' } }}
          >
            <Value lteSmall={true}>{decimalMultiplier ? `${Math.floor(multiplier)}` : multiplier}x</Value>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ paddingLeft: { md: '80px' }, paddingRight: { md: '80px' } }}>
          <TitleText lteSmallTop={true}>
            {multiplier}x FOR {durationDays} DAYS
          </TitleText>
          <TitleText lteSmallBottom={true}>JUST FOR YOU!</TitleText>
          <Expires smallerDevices={true} />
        </Grid>
        <MultiplierButton onClick={props.onClick} />
      </Grid>
    </>
  )
}

export default Title
