import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography } from '@mui/material'

interface LnurlTextProps {
  lnurl: string
  openCancelDialog: boolean
  copyLnurl: () => void
}

interface LnurlQrCodeDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const LnurlText = (props: LnurlTextProps) => {
  const { lnurl, openCancelDialog, copyLnurl } = props

  return (
    <>
      <Box
        sx={{
          border: 1,
          borderColor: 'rgb(209 213 219)',
          width: '100%',
          height: '60px',
          display: 'flex',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            borderRadius: '8px',
            flex: 1, // This will make the box take up the remaining space
            bgcolor: '#f2f6fa', // This will set the background color to grey
            display: 'flex', // Set display to flex
            alignItems: 'center', // Center content vertically
            fontSize: '14px',
            fontWeight: '500',
            overflow: 'auto', // Make the box scrollable
            paddingRight: '10px', // Add right padding
            whiteSpace: 'nowrap', // Prevent line breaks
            paddingLeft: '20px',
            '&::-webkit-scrollbar': {
              display: 'none', // Hide scrollbar for Chrome, Safari and Opera
            },
            scrollbarWidth: 'none', // Hide scrollbar for Firefox
          }}
        >
          {lnurl}
        </Box>

        <Button
          onClick={copyLnurl}
          disabled={openCancelDialog}
          color="primary"
          size="small"
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            width: '50px', // This will make the box take up a fixed amount of space
            bgcolor: '#00A2FF', // This will set the background color to blue
            height: '100%', // Set the height to 100%
            padding: '6px 10px',
            minWidth: 'auto',
            display: 'flex', // Set display to flex
            alignItems: 'center', // Center content vertically
            '&:hover': {
              backgroundColor: '#0086cc', // Adjust the hover color as needed
            },
          }} // Adjust these values as needed
        >
          <ContentCopyIcon sx={{ color: 'white' }} />
        </Button>
      </Box>
    </>
  )
}

export const LnurlQrCode = ({ lnurlQrCode }: { lnurlQrCode: string }) => {
  return (
    <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12}>
        <Typography
          component="div"
          align="center"
          sx={{ fontSize: { xs: '14px', sm: '16px' }, fontWeight: 700, paddingLeft: '20px', paddingRight: '20px' }}
        >
          Scan the QR Code using your lightning wallet
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
        {lnurlQrCode && <img src={lnurlQrCode} alt="QR Code" style={{ maxWidth: '160px', maxHeight: '160px' }} />}
      </Grid>
    </Grid>
  )
}

export const LnurlQrCodeDialog = ({ open, onClose, onConfirm }: LnurlQrCodeDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogContent>
        <DialogContentText textAlign="center" sx={{ fontWeight: 700, color: 'black' }}>
          Did you open your lightning wallet?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{}}>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: 'white',
            color: '#0086cc',
            borderRadius: '5px',
            border: '1px solid',
            borderColor: '#00A2FF',
            padding: '10px 20px',
            fontSize: '16px',
            width: '100%',
          }}
        >
          No
        </Button>
        <Button
          onClick={onConfirm}
          sx={{
            width: '100%',
            backgroundColor: '#00A2FF',
            color: 'white',
            borderRadius: '5px',
            padding: '10px 20px',
            fontSize: '16px',
            '&:hover': {
              backgroundColor: '#00A2FF',
            },
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
