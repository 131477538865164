/**
 * Coinbase container that utilizes the MUI Box component as opposed to the Card Component
 * because overriding the card component is difficult (needed for banner), esepcially since many
 * other components use it.
 */

import { Box } from '@mui/material'

const ProcessBox = (props: any) => {
  return (
    <Box
      sx={{
        width: {
          xxs: '250px',
          xs: '350px',
          md: '400px',
          lg: '400px',
          xl: '400px',
        },
        background: '#FFFFFF',
        boxShadow: '0px 7px 11px #02073424',
        borderRadius: '10px',
        overflow: 'hidden',
        marginBottom: '13px',
        paddingBottom: '25px',
        opacity: 1,
      }}
    >
      {props.children}
    </Box>
  )
}

export default ProcessBox
