import { Typography } from '@mui/material'

const Description = (props: any) => {
  return (
    <Typography color="textPrimary">
      {props.selectedCashoutPlatform && props.selectedCashoutPlatform} is a digital wallet where you can manage your currency
      portfolio.
    </Typography>
  )
}

export default Description
