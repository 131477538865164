import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { httpsCallable } from 'firebase/functions'
import { firebaseAuth, firebaseFunctions } from '../../firebase/firebase'
import { BaseContext } from '../models'
import { setServerError } from './auth'
import { cashoutConfig } from './cashOut'

export interface LoadingConfigs {
  userRewardConfig: boolean
  cashOutConfig: boolean
}

export interface SupportedCurrency {
  decimalPlaces: number
  imageUrl: string
  name: string
  symbol: string
}

export interface UserState {
  blingEmail: string
  blingPoints: number
  blingPointsBTCValue: string // used for displaying bitcoin conversion value
  blingPointsUSDValue: string // used for displaying usd conversion value
  loadingUserConfig: boolean
  loadingConfigs: LoadingConfigs
  entities: unknown[]
  appDeviceGuid: string
  userAgent: string
  webView: boolean
  safari: boolean
}

const initialState: UserState = {
  blingEmail: '',
  blingPoints: 0,
  blingPointsBTCValue: '',
  blingPointsUSDValue: '',
  loadingUserConfig: false,
  loadingConfigs: {
    userRewardConfig: false,
    cashOutConfig: false,
  },
  entities: [],
  appDeviceGuid: '',
  userAgent: '',
  webView: false,
  safari: false,
}

export const userRewardConfig = createAsyncThunk('userRewardConfig', async (baseContext: BaseContext, { dispatch }) => {
  try {
    if (!firebaseAuth.currentUser) throw new Error('User is not signed in.')

    const userRewardConfig = httpsCallable(firebaseFunctions, 'userRewardConfig')
    const result = await userRewardConfig(baseContext)

    const data = result.data as UserState

    dispatch(setLoadingUserConfig(true))
    dispatch(setBlingPoints(data.blingPoints))
    dispatch(setBlingPointsBTCValue(data.blingPointsBTCValue))
    dispatch(setBlingPointsUSDValue(data.blingPointsUSDValue))
  } catch (err) {
    dispatch(setServerError(true))
  }
})

// Wait for userRewardConfig and cashOutConfig to load
export const loadConfigs = createAsyncThunk('loadConfigs', async (baseContext: BaseContext, { dispatch }) => {
  dispatch(
    setLoadingConfigs({
      userRewardConfig: true,
      cashOutConfig: true,
    }),
  )

  const complete = await Promise.all([dispatch(userRewardConfig(baseContext)), dispatch(cashoutConfig(baseContext))])
  if (complete) {
    dispatch(
      setLoadingConfigs({
        userRewardConfig: false,
        cashOutConfig: false,
      }),
    )
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setBlingEmail: (state, action: PayloadAction<string>) => {
      state.blingEmail = action.payload
    },
    setBlingPoints: (state, action: PayloadAction<number>) => {
      state.blingPoints = action.payload
    },
    setBlingPointsBTCValue: (state, action: PayloadAction<string>) => {
      state.blingPointsBTCValue = action.payload
    },
    setBlingPointsUSDValue: (state, action: PayloadAction<string>) => {
      state.blingPointsUSDValue = action.payload
    },
    setLoadingUserConfig: (state, action: PayloadAction<boolean>) => {
      state.loadingUserConfig = action.payload
    },
    setLoadingConfigs: (state, action: PayloadAction<LoadingConfigs>) => {
      state.loadingConfigs = action.payload
    },
    setAppDeviceGuid: (state, action: PayloadAction<string>) => {
      state.appDeviceGuid = action.payload
    },
    setUserAgent: (state, action: PayloadAction<string>) => {
      state.userAgent = action.payload
    },
    setWebView: (state, action: PayloadAction<boolean>) => {
      state.webView = action.payload
    },
    setSafari: (state, action: PayloadAction<boolean>) => {
      state.safari = action.payload
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(userRewardConfig.fulfilled, (state, action) => {
      // Add user to the state array
      //TODO: Test entities. Might receive return values from cloud functions. May be useful for later if this works
      state.entities.push(action.payload)
    })
  },
})

export const {
  setBlingEmail,
  setBlingPoints,
  setBlingPointsBTCValue,
  setBlingPointsUSDValue,
  setLoadingUserConfig,
  setLoadingConfigs,
  setAppDeviceGuid,
  setUserAgent,
  setWebView,
  setSafari,
} = userSlice.actions
