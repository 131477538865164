import QrCodeIcon from '@mui/icons-material/QrCode'
import { Grid, Button } from '@mui/material'

const ProcessButton = (props: any) => {
  return (
    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={props.cashOutButtonDisabled}
        startIcon={<QrCodeIcon style={{ fontSize: '35px' }} />}
        sx={{
          textTransform: 'none', // Make the text not all uppercase
          border: '1px solid #D3D3D3',
          width: { xxs: '220px', xs: '300px', sm: '320px' },
          height: '60px',
          borderRadius: '8px',
          color: '#FFFFFF;',
          background: 'linear-gradient(195deg, #ffce00, #ffb700 50%, #ff7300)',
          boxShadow: '0px 3px 0px #00000029, inset 0 -3px 3px rgba(0,0,0,0.2)', // Add inner box shadow at the bottom
          fontWeight: 800,
          fontSize: '20px',
        }}
      >
        Lightning Cash Out
      </Button>
    </Grid>
  )
}

export default ProcessButton
