import { Grid, Typography } from '@mui/material'

const LatestCashOutTitle = () => {
  return (
    <Grid item xs={12}>
      <Typography align="center" sx={{ fontWeight: 600, fontSize: '15px' }}>
        LAST CASH OUT
      </Typography>
    </Grid>
  )
}

export default LatestCashOutTitle
