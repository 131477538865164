/**
 * Prompt user for their cash out email to begin the cash out process
 */
import * as yup from 'yup'
import TopBanner from './TopBanner'
import Loader from '../../components/CashOut/Loader/Loader'
import ProcessButton from '../../components/CashOut/Process/ProcessButton'
import ProcessDesc from '../../components/CashOut/Process/ProcessDesc'
import ProcessEmail from '../../components/CashOut/Process/ProcessEmail'
import ProcessHeader from '../../components/CashOut/Process/ProcessHeader'
import ProcessTitle from '../../components/CashOut/Process/ProcessTitle'
import ProcessBox from '../../components/CashOut/Templates/Box/ProcessBox'
import BottomLayout from '../../layouts/CashOut/BottomLayout'
import Layout from '../../layouts/CashOut/Layout'
import LightningProcess from '../../components/CashOut/Process/LightningProcess'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store'
import {
  setCashOutErrorMessage,
  setCashOutButtonDisabled,
  setLoadingCashOut,
  redeemBlingPoints,
} from '../../redux/slices/cashOut'
import { CashOutContext } from '../../redux/models'
import { useEffect, useState } from 'react'

const CashOutProcess = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { blingEmail, appDeviceGuid, userAgent, webView } = useSelector((state: RootState) => state.user)
  const {
    cashOutConfig,
    selectedCurrency,
    loadingCashOutConfig,
    loadingCashOut,
    cashOutErrorMessage,
    selectedCoinbase,
    lastestCashOutEmailInput,
    cashOutButtonDisabled,
    selectedCashoutPlatform,
    selectedLightningWallet,
  } = useSelector((state: RootState) => state.cashOut)

  // session email if we ever have to re-render Process.tsx
  const [initialEmail, setInitialEmail] = useState('')

  // Custom validation to ensure users don't cash out to an apple private relay email
  yup.addMethod(yup.string, 'checkPrivateRelay', function (errorMessage) {
    return this.test(`check-private-relay`, errorMessage, function (value) {
      const { path, createError } = this
      if (value && value.toLowerCase().includes('@privaterelay.appleid.com')) {
        return createError({ path, message: errorMessage })
      }
      return true
    })
  })

  const validationSchema = yup.object({
    email: (yup as any)
      .string()
      .email('Enter a valid email.')
      .required('Email is required.')
      .checkPrivateRelay('Private relay emails are no longer supported for cashing out. Please use a different email.'),
  })

  useEffect(() => {
    const emailFromSession = sessionStorage.getItem('lastCashOutEmailSubmitted')
    if (emailFromSession) {
      sessionStorage.removeItem('lastCashOutEmailSubmitted')
      setInitialEmail(emailFromSession)
    } else {
      setInitialEmail(
        selectedCoinbase.option === 'existingCoinbaseAccount'
          ? ''
          : cashOutConfig &&
            cashOutConfig.lastCashoutEmails &&
            selectedCashoutPlatform.toLowerCase() in cashOutConfig.lastCashoutEmails
          ? cashOutConfig.lastCashoutEmails[selectedCashoutPlatform.toLowerCase()]
          : blingEmail,
      )
    }
  }, [selectedCoinbase, cashOutConfig, selectedCashoutPlatform, blingEmail])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: initialEmail,
    },
    validationSchema: selectedCashoutPlatform.toLowerCase() !== 'lightning' && validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const v = values as { email: string; symbol: string }

      // Save the email to session storage
      sessionStorage.setItem('lastCashOutEmailSubmitted', v.email)

      // Clear any previous errors
      resetForm()
      dispatch(setCashOutErrorMessage(''))

      // Start the cash out process
      dispatch(setCashOutButtonDisabled(true))
      dispatch(setLoadingCashOut(true))
      dispatch(
        redeemBlingPoints({
          cashOutContext: new CashOutContext(
            v.email,
            selectedCurrency.symbol,
            selectedCashoutPlatform.toUpperCase(),
            appDeviceGuid,
            userAgent,
            webView,
          ),
          lightningUrlScheme: selectedLightningWallet?.urlScheme,
        }),
      )
    },
  })

  // Make UX better for multiple cash outs per coinbase email flow by disabling cash out button
  useEffect(() => {
    if (formik.values.email !== lastestCashOutEmailInput && cashOutButtonDisabled) dispatch(setCashOutButtonDisabled(false))
    if (formik.values.email === lastestCashOutEmailInput) dispatch(setCashOutButtonDisabled(true))
    if (selectedCashoutPlatform.toLowerCase() === 'lightning') dispatch(setCashOutButtonDisabled(false))
  }, [cashOutButtonDisabled, dispatch, formik.values, lastestCashOutEmailInput, selectedCashoutPlatform])

  return (
    <Layout>
      <TopBanner />
      {!loadingCashOut && !loadingCashOutConfig ? (
        <BottomLayout>
          <form onSubmit={formik.handleSubmit}>
            {selectedCashoutPlatform.toLowerCase() !== 'lightning' ? (
              <ProcessBox>
                <ProcessHeader />
                <ProcessTitle />
                <ProcessDesc />
                <ProcessEmail selectedCoinbase={selectedCoinbase} formik={formik} cashOutErrorMessage={cashOutErrorMessage} />
                <ProcessButton cashOutButtonDisabled={cashOutButtonDisabled} />
              </ProcessBox>
            ) : (
              // TODO: Use below if we want to support url scheme approach for lightning safari
              //   <>{safari ? <LightningSafariProcess /> : <LightningProcess />}</>
              <LightningProcess />
            )}
          </form>
        </BottomLayout>
      ) : (
        <BottomLayout>
          <Loader height="35vh" />
        </BottomLayout>
      )}
    </Layout>
  )
}

export default CashOutProcess
