import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ShowAlertPayload {
  title: string
  message: string
}

export interface AlertState {
  showAlert: boolean
  title: string
  message: string
}

const initialState: AlertState = {
  showAlert: false,
  title: '',
  message: '',
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert(state, action: PayloadAction<ShowAlertPayload>) {
      state.showAlert = true
      state.title = action.payload.title
      state.message = action.payload.message
    },
    hideAlert(state) {
      state.showAlert = false
      state.title = ''
      state.message = ''
    },
  },
})

export const { showAlert, hideAlert } = alertSlice.actions
