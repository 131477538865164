/**
 * Context data that the backend will receive via the countries cloud function
 */

import { BaseContext } from '.'

class CountryContext extends BaseContext {
  constructor(
    public countryCode: string,
    appdeviceguid: string,
    userAgent: string,
    webView: boolean,
  ) {
    super(appdeviceguid, userAgent, webView)
  }
}

export default CountryContext
