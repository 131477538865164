import { Grid } from '@mui/material'
import { Props } from '../../types'

const DetailsLayout = ({ children }: Props) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: { xxs: '30px', xs: '50px', md: '101px' },
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: { xs: '90%', md: '900px' },
          background: '#29146B 0% 0% no-repeat padding-box',
          boxShadow: 'inset 0px 3px 6px #00000029',
          border: '5px solid #2CD0E9',
          borderRadius: '35px',
          opacity: 0.8,
          paddingBottom: '20px',
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}

export default DetailsLayout
