import { Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const Loader = (props: any) => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: props.height || '70vh',
      }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

export default Loader
