import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const ConversionText = (props: any) => {
  const { selectedCurrency } = useSelector((state: RootState) => state.cashOut)

  return (
    <Grid item xs={12}>
      <Typography
        align="center"
        sx={{
          fontSize: '12px',
          fontWeight: 700,
          textTransform: 'uppercase',
          color: '#1EA0F0;',
          opacity: 1,
        }}
      >
        {selectedCurrency.name === 'Bitcoin' ? 'Value' : 'Convert To'}
      </Typography>
    </Grid>
  )
}

export default ConversionText
