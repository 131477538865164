import { Grid, Typography } from '@mui/material'

const CountryTitle = () => {
  return (
    <Grid item xs={12}>
      <Typography
        align="center"
        variant="h5"
        color="textPrimary"
        sx={{
          marginLeft: '10px',
          marginTop: '10px',
          marginBottom: '20px',
          fontWeight: 700,
        }}
      >
        Select a Country
      </Typography>
    </Grid>
  )
}

export default CountryTitle
