import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { AppDispatch, RootState } from '../../../redux/store'
import { CountryContext } from '../../../redux/models'
import { submitCountry as submitCountryAction } from '../../../redux/slices/cashOut'
import SubmitCountryButton from './SubmitCountryButton'
import Loader from '../Loader/Loader'
import SearchCountryField from './SearchCountryField'
import SelectCountryField from './SelectCountryField'

/**
 * Main UI for the user selecting their country of residence. Mainly consists of the SelectCountryField and SearchCountryField.
 * @constructor
 */
const CountrySelector = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { appDeviceGuid, userAgent, webView } = useSelector((state: RootState) => state.user)
  const { countryData } = useSelector((state: RootState) => state.cashOut)

  const [selectedCountry, setSelectedCountry] = useState(countryData?.lastCountryCode || '')
  const [searchTerm, setSearchTerm] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const isMounted = useRef(true)

  // Controls when the UI changes to display the country search list.
  const shouldShowSearch = (show: boolean) => {
    setShowSearch(show)
    if (show) {
      setHasError(false)
    }
  }

  // Has the state of whether we're still on the country selector
  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  // Sets the text box to the country selected by the user.
  const setCountry = useCallback(
    (key: string) => {
      setSelectedCountry(key)
      setShowSearch(false)
    },
    [setSelectedCountry],
  )

  // Checks whether the input in the text box is valid (not empty and not the default value)
  const isInvalidInput = useCallback((input: string): boolean => {
    return !input
  }, [])

  // Sends the country that the user selected to the backend to process
  const submitCountry = useCallback((): void => {
    if (isInvalidInput(selectedCountry)) {
      setHasError(true)
      return
    }
    const countryContext = new CountryContext(selectedCountry, appDeviceGuid, userAgent, webView)
    setShowLoading(true)
    dispatch(submitCountryAction(countryContext)).finally(() => {
      if (isMounted.current) {
        setShowLoading(false)
      }
    })
  }, [selectedCountry, appDeviceGuid, userAgent, webView, isInvalidInput, dispatch])

  // Filtered countries by the text in the search input field using name and country code
  const filteredCountries = useMemo(
    () =>
      Object.entries(countryData?.countryDictionary || {}).filter(
        ([key, value]) =>
          key.toLowerCase().includes(searchTerm.toLowerCase()) || value.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [countryData, searchTerm],
  )

  return (
    <Box>
      {showLoading ? (
        <Loader height="35vh" />
      ) : (
        <>
          {!showSearch ? (
            <>
              <SelectCountryField
                countryName={countryData?.countryDictionary[selectedCountry] || 'Select a country'}
                setShowSearch={shouldShowSearch}
                hasError={hasError}
              />
              <Box sx={{ width: '100%', mt: 2 }}>
                <SubmitCountryButton onSubmit={submitCountry} />
              </Box>
            </>
          ) : (
            <SearchCountryField
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filteredCountries={filteredCountries}
              setCountry={setCountry}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default CountrySelector
