import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const SuccessTitle = () => {
  const { cashOutConfig } = useSelector((state: RootState) => state.cashOut)

  return (
    <Grid item xs={12}>
      <Typography align="center" sx={{ fontWeight: 700, fontSize: { xs: '18px', sm: '22px' } }}>
        Your {cashOutConfig.lastCashout.cryptoName} is on its way!
      </Typography>
    </Grid>
  )
}

export default SuccessTitle
