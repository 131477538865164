/**
 * General user client data that will serve as a base context to give us better insights on the user from the backend
 */

import isWebview from 'is-ua-webview'
import { v4 as uuidv4 } from 'uuid'

export interface WebView {
  mobileSafari: boolean
  webView: boolean
  userAgent: string
}

// Generates a uuid to be used as the appdeviceguid
export const generateGuid = (): string => {
  const appDeviceGuid = uuidv4()
  return appDeviceGuid
}

// Mainly for using lightning url schemes in iOS
export const isMobileSafari = (userAgent: string) => {
  const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i) || !!userAgent.match(/iPod/i)
  const webkit = !!userAgent.match(/WebKit/i)
  const iOSSafari = iOS && webkit && !userAgent.match(/CriOS/i) && !userAgent.match(/OPiOS/i)

  return iOSSafari
}

// Google OAuth 2.0 policy disallows WebView
export const checkWebView = (): WebView => {
  let webView = false
  if (isWebview(navigator.userAgent)) webView = true
  const userAgent = navigator.userAgent

  const mobileSafari = isMobileSafari(userAgent)

  return { webView, userAgent, mobileSafari }
}
