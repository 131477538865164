/**
 * Status 404: Not Found Error
 */

import { Box, Grid, Typography } from '@mui/material'

const NotFound = () => {
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: '260px' }}>
        <Box component="img" src="https://uploads-ssl.webflow.com/static/page-not-found.211a85e40c.svg" sx={{ width: '256px' }} />
        <Typography
          sx={{
            fontSize: '32px',
            lineHeight: '36px',
            marginTop: '20px',
            fontFamily: 'Nunito Sans',
            fontWeight: 700,
            marginBottom: '10px',
            color: '#333',
            textAlign: 'center',
          }}
        >
          Page Not Found
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'Nunito Sans',
            textAlign: 'center',
          }}
        >
          The page you are looking for doesn't exist or has been moved.
        </Typography>
      </Box>
    </Grid>
  )
}

export default NotFound
