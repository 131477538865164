/**
 * Overall layout for the unique multiplier.
 */
import { Box } from '@mui/material'
import { Props } from '../../types'

const Layout = ({ children }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent linear-gradient(212deg, #291367 0%, #8849EF 61%, #BE60F7 100%) 0% 0% no-repeat padding-box',
        minHeight: '100vh',
        overflow: 'hidden',
        paddingBottom: '20px',
      }}
    >
      {children}
    </Box>
  )
}

export default Layout
