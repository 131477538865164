/**
 * Page for users that have a unique multiplier.
 */

import Gradient from '../../layouts/Multiplier/Gradient'
import Details from '../../components/Mutliplier/Unique/Details/Details'
import { Alert, Backdrop, CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store'
import { applyUniqueMultiplier, setLoadingMultiplier } from '../../redux/slices/multiplier'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import Footer from '../../components/Mutliplier/Unique/Footer/Footer'
import Header from '../../components/Mutliplier/Unique/Header/Header'
import Content from '../../layouts/Multiplier/Content'
import TitleSection from '../../components/Mutliplier/Unique/TitleSection'
import Layout from '../../layouts/Multiplier/Layout'

const Unique = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()
  const { uniqueMultiplierActivated, multiplierError, loadingMultiplier, multiplierStatus } = useSelector(
    (state: RootState) => state.multiplier,
  )

  // Activate the multiplier for the user
  const onClick = () => {
    dispatch(applyUniqueMultiplier(id))
  }

  useEffect(() => {
    // User just activated their multiplier
    if (uniqueMultiplierActivated) {
      window.location.replace('https://www.blingfi.com/activated?status=active')
    }
  }, [multiplierError, multiplierStatus, uniqueMultiplierActivated])

  return (
    <>
      {multiplierError && (
        <Alert severity="error">Failed to activate your multiplier. Please try again or contact customer support.</Alert>
      )}
      <Layout>
        <Gradient />
        <Content>
          <Header />
          <TitleSection onClick={onClick} />
          <Details />
          <Footer onClick={onClick} />

          {loadingMultiplier && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loadingMultiplier}
              onClick={() => dispatch(setLoadingMultiplier(false))}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Content>
      </Layout>
    </>
  )
}

export default Unique
