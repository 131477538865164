import React, { useMemo } from 'react'
import { Box, Grid, TextField } from '@mui/material'
import CountryButton from '../../CashOut/Country/CountryButton'

interface SearchCountryFieldProps {
  searchTerm: string
  setSearchTerm: (term: string) => void
  filteredCountries: [string, string][]
  setCountry: (key: string) => void
}

const SearchCountryField: React.FC<SearchCountryFieldProps> = ({ searchTerm, setSearchTerm, filteredCountries, setCountry }) => {
  return (
    <Box>
      <TextField
        label="Search by country name or code"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {useMemo(
        () =>
          filteredCountries.map(([key, value]) => (
            <Grid
              key={key}
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CountryButton name={value} value={key} onSubmit={() => setCountry(key)} />
            </Grid>
          )),
        [filteredCountries, setCountry],
      )}
    </Box>
  )
}

export default SearchCountryField
