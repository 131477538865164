import { Grid } from '@mui/material'

const TopLayout = (props: any) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="200px"
      paddingBottom={props.paddingBottom}
      sx={{
        background: 'transparent linear-gradient(40deg, #175de4 0%, #2cdfff 100%)',
        width: '100%',
      }}
    >
      {props.children}
    </Grid>
  )
}

export default TopLayout
