import { Grid, TextField } from '@mui/material'

const VerifyCode = (props: any) => {
  return (
    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
      <TextField
        id="code"
        name="code"
        variant="outlined"
        autoFocus
        value={props.formik.values.code}
        onChange={props.formik.handleChange}
        error={(props.formik.touched.code && Boolean(props.formik.errors.code)) || props.verifyErrorMessage ? true : false} // If formik error or custom error
        helperText={(props.formik.touched.code && props.formik.errors.code) || props.verifyErrorMessage} // Display validation or custom error messsage
        inputProps={{
          min: 0,
          style: { textAlign: 'center' },
        }}
        sx={{ width: '90%' }}
      />
    </Grid>
  )
}

export default VerifyCode
