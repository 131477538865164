/**
 * Route the user to the correct cash out page
 */

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useParams } from 'react-router-dom'
import { uniqueMultiplierAvailable } from '../../redux/slices/multiplier'
import { AppDispatch } from '../../redux/store'

const UniqueMultiplier = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(uniqueMultiplierAvailable(id))
  }, [dispatch, id])

  return <Outlet />
}

export default UniqueMultiplier
