/**
 * Contains the Firebase UI Library configuration.
 */
import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth'

/**
 * FirebaseUI Configuration
 * An out-of-box solution for Firebase Auth
 * This will be replaced if we want to customize the styling/UI for the login buttons
 */
export const firebaseUIConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  signInOptions: [
    // Email and Password Provider
    {
      provider: new EmailAuthProvider().providerId,
      disableSignUp: {
        status: true,
      },
      fullLabel: 'Continue with Email',
      requiredDisplayName: false,
    },
    // Google Provider
    {
      provider: new GoogleAuthProvider().providerId,
      customParameters: {
        // Forces account selection even when one account is available.
        prompt: 'select_account',
      },
      fullLabel: 'Continue with Google',
    },
    // Facebook Provider
    {
      provider: new FacebookAuthProvider().providerId,
      customParameters: {
        // Forces password re-entry.
        auth_type: 'reauthenticate',
      },
      fullLabel: 'Continue with Facebook',
    },
    // Apple Provider
    {
      provider: new OAuthProvider('apple.com').providerId,
      buttonColor: '#2F2F2F',
      fullLabel: 'Continue with Apple',
    },
  ],
  // tosUrl: 'https://www.blingfi.com/privacy',
  // privacyPolicyUrl: 'https://www.blingfi.com/privacy',
}
