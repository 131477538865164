/**
 * Hook to poll cash out status after a cash out has been created
 * e.g. user has started lightning cash out and needs to complete in wallet
 * when they return we should poll the status of the cash out and update the page
 */

import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../redux/store'
import { BaseContext } from '../redux/models'
import { pollLastCashOutStatus } from '../redux/slices/cashOut'

interface PollingProps {
  baseContext: BaseContext
  pollStatus: 'PENDING' | 'PENDING_LIGHTNING'
}

const MAX_COUNT = 60

export const usePolling = ({ baseContext, pollStatus }: PollingProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const handleFocus = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
      dispatch(pollLastCashOutStatus({ baseContext, pollStatus }))
      startPolling()
    }

    const startPolling = () => {
      let count = 0
      intervalRef.current = setInterval(() => {
        count += 1
        if (count < MAX_COUNT) {
          dispatch(pollLastCashOutStatus({ baseContext, pollStatus }))
        } else {
          if (intervalRef.current) clearInterval(intervalRef.current)
        }
      }, 10000)
    }

    dispatch(pollLastCashOutStatus({ baseContext, pollStatus }))
    startPolling()

    window.addEventListener('focus', handleFocus)

    return () => {
      window.removeEventListener('focus', handleFocus)
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [baseContext, dispatch, pollStatus])
}
