import { Grid } from '@mui/material'
import BlingLogo from './BlingLogo'

const Header = (props: any) => {
  return (
    <Grid container sx={{ paddingTop: '31px', paddingLeft: { md: '53px' } }}>
      <Grid item xs={12}>
        <BlingLogo blueLogo={props.blueLogo} />
      </Grid>
    </Grid>
  )
}

export default Header
