import React from 'react'
import { Box, Button, TextField } from '@mui/material'

interface SelectCountryFieldProps {
  countryName: string
  setShowSearch: (show: boolean) => void
  hasError?: boolean
}

/**
 * The UI that has the currently selected country, and the button to change it which will open up the SearchCountryField.
 * @param countryName
 * @param setShowSearch
 * @param hasError
 * @constructor
 */
const SelectCountryField: React.FC<SelectCountryFieldProps> = ({ countryName, setShowSearch, hasError }) => {
  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <TextField
        value={countryName || 'Select a country'}
        variant="outlined"
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <Button
              variant="text"
              onClick={() => setShowSearch(true)}
              sx={{
                color: '#1ca9f2',
                textTransform: 'none',
                ml: 2,
                position: 'absolute',
                right: 15,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              Change
            </Button>
          ),
        }}
        error={hasError}
        helperText={hasError ? 'Invalid country submitted. Please try again.' : ''}
        sx={{ flexGrow: 1 }}
        onClick={() => setShowSearch(true)}
      />
    </Box>
  )
}

export default SelectCountryField
