import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { BLING_CROWN, MULTIPLIER_VALUE_BACKGROUND } from '../../../../constants'

const DetailOne = () => {
  const { multiplier, decimalMultiplier } = useSelector((state: RootState) => state.multiplier)

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ display: { xxs: 'none', xs: 'flex' }, justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}
      >
        <Box
          sx={{
            background: '#6851AA 0% 0% no-repeat padding-box',
            borderRadius: '12px',
            opacity: 1,
            width: { xxs: '90%', sm: '540px', md: '600px' },
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <Box
                    component="img"
                    sx={{
                      width: { xs: '80px', sm: '120px' },
                    }}
                    src={MULTIPLIER_VALUE_BACKGROUND}
                  />

                  <Box
                    component="img"
                    sx={{
                      width: { xs: '45px', sm: '70px' },
                      marginTop: { xs: '-40px', sm: '-65px' },
                      position: 'absolute',
                    }}
                    src={BLING_CROWN}
                  />
                  <Box sx={{ position: 'absolute', marginTop: { xxs: '15px', sm: '20px' }, marginLeft: '20px' }}>
                    <Typography
                      sx={{
                        fontWeight: 900,
                        fontFamily: 'FatFrank',
                        fontSize: { xs: '35px', sm: '55px' },
                        color: '#FFFFFF',
                        opacity: 1,
                        textShadow: '0px -1px 0.5px #C657E58B',
                        marginTop: { xs: '15px', sm: '20px' },
                        marginRight: { xs: '20px', sm: '20px' },
                        display: 'inline',
                      }}
                    >
                      {decimalMultiplier ? `${Math.floor(multiplier)}` : multiplier}x
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { xxs: '1.3rem', sm: '1.6rem' },
                  color: '#46FFF8',
                  fontWeight: 900,
                  lineHeight: 1.1,
                  paddingRight: { xs: '10px' },
                }}
              >
                Gameplay rewards will be multiplied by {multiplier}x
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: { xxs: 'flex', xs: 'none' }, justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}
      >
        <Box
          sx={{
            background: '#6851AA 0% 0% no-repeat padding-box',
            borderRadius: '12px',
            opacity: 1,
            width: { xxs: '90%' },
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: { xxs: '1.1rem', xs: '1.3rem', sm: '1.6rem' },
              color: '#46FFF8',
              fontWeight: 900,
              lineHeight: 1.1,
              paddingRight: { xs: '10px' },
            }}
          >
            Gameplay rewards will be multiplied by {multiplier}x
          </Typography>
        </Box>
      </Grid>
    </>
  )
}

export default DetailOne
