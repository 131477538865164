import { Typography } from '@mui/material'

const VerifyEmail = (props: any) => {
  return (
    <Typography align="center" sx={{ marginBottom: '10px', fontWeight: 700, fontSize: '15px' }}>
      {props.verifyEmail}
    </Typography>
  )
}

export default VerifyEmail
