/**
 * User's cash out is available, but doesn't have the minimum required points to cash out
 */

import TopBanner from './TopBanner'
import Layout from '../../layouts/CashOut/Layout'
import BottomLayout from '../../layouts/CashOut/BottomLayout'
import MessageBox from '../../components/CashOut/Templates/Box/MessageBox'
import MinPointsMessage from '../../components/CashOut/MinPoints/MinPointsMessage'

const MinPoints = () => {
  return (
    <Layout>
      <TopBanner />
      <BottomLayout paddingTop="90px">
        <MessageBox>
          <MinPointsMessage />
        </MessageBox>
      </BottomLayout>
    </Layout>
  )
}

export default MinPoints
