import { Box, Button } from '@mui/material'

const PlatformButton = (props: any) => {
  return (
    <Button
      variant="contained"
      type="button"
      sx={{
        justifyContent: 'left',
        marginBottom: '10px',
        width: { xxs: '280px', xs: '350px' },
        height: '70px',
        color: 'black',
        fontWeight: 400,
        fontSize: '16px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: ' 0px 3px 6px #00000029',
        borderRadius: '5px',
        '&:hover': {
          background: '#FFFFFF 0% 0% no-repeat padding-box',
        },
      }}
      name={props.name}
      value={props.value}
      onClick={(e) => props.onSubmit(e)}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          paddingLeft: '14px',
        }}
      >
        {/* TODO: Backend returns 'coinbase', so format to 'Coinbase' for now. Change this when we can truly rely on backend platform.name */}
        <Box sx={{ textTransform: 'none' }}>{props.name[0].toUpperCase() + props.name.slice(1)}</Box>
      </Box>
    </Button>
  )
}

export default PlatformButton
