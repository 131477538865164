/**
 * Apply gradient background to overall layout for unique multiplier.
 */
import { Box } from '@mui/material'

const Gradient = () => {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {/* Top Right Blue */}
      <Box
        sx={{
          marginLeft: { xxs: '40px', sm: '100px', md: '450px', lg: '600px' },
          marginTop: { xs: '-150px', sm: '-50px' },
          height: { xxs: '600px', xs: '700px', sm: '700px', md: '900px' },
          width: { xxs: '600px', xs: '700px', sm: '700px', md: '900px' },
          position: 'absolute',
          opacity: 0.4,
          background:
            'transparent radial-gradient(closest-side at 50% 50%, #2CD0E9 0%, #2CD0E9 35%, #1E26BF00 100%) 0% 0% no-repeat padding-box',
        }}
      />

      {/* Top Left Orange */}
      <Box
        sx={{
          marginLeft: '-700px',
          marginTop: '50px',
          height: '1200px',
          width: '1200px',
          position: 'absolute',
          opacity: 0.4,
          background:
            'transparent radial-gradient(closest-side at 50% 50%, #FDD738 0%, #FE955D 25%, #FF10ED00 100%) 0% 0% no-repeat padding-box',
        }}
      />

      {/* Bottom Left Orange*/}
      <Box
        sx={{
          display: { xxs: 'none', sm: 'flex' },
          marginLeft: { md: '-400px', lg: '-200px' },
          marginTop: '1500px',
          height: '1200px',
          width: '1200px',
          position: 'absolute',
          opacity: 1,
          background:
            'transparent radial-gradient(closest-side at 50% 50%, #FDD738 0%, #FE955D 33%, #FF10ED00 100%) 0% 0% no-repeat padding-box',
        }}
      />
    </Box>
  )
}

export default Gradient
