/**
 * Background image for the multiplier value
 */
import { Box } from '@mui/material'
import { MULTIPLIER_VALUE_BACKGROUND } from '../../../../../constants'

const Circle = () => {
  return (
    <Box
      component="img"
      sx={{
        width: { xxs: '200px', sm: '300px', md: '400px' },
        height: { xxs: '200px', sm: '300px', md: '400px' },
      }}
      src={MULTIPLIER_VALUE_BACKGROUND}
    />
  )
}

export default Circle
