import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

export const ProcessDesc = () => {
  const { selectedCashoutPlatform } = useSelector((state: RootState) => state.cashOut)

  return (
    <>
      <Typography
        sx={{
          fontSize: '16px',
          paddingLeft: '18px',
          paddingRight: '18px',
          paddingBottom: '16px',
        }}
      >
        This is the {selectedCashoutPlatform} email address that you are cashing out to.
      </Typography>
    </>
  )
}

export default ProcessDesc
