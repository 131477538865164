/**
 * Displays the success message after successfully cashing out (before Coinbase processes)
 */
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { RootState } from '../../redux/store'
import Loader from '../../components/CashOut/Loader/Loader'
import MessageBox from '../../components/CashOut/Templates/Box/MessageBox'
import BottomLayout from '../../layouts/CashOut/BottomLayout'
import Layout from '../../layouts/CashOut/Layout'
import TopBanner from './TopBanner'

const AccountPendingReview = () => {
  const { cashOutConfig, loadingCashOutConfig } = useSelector((state: RootState) => state.cashOut)

  return (
    <Layout>
      <TopBanner />
      <BottomLayout paddingTop="100px">
        {!loadingCashOutConfig ? (
          <MessageBox>
            <Grid item xs={12}>
              <Typography align="center" sx={{ fontWeight: 700, fontSize: { xs: '18px', sm: '22px' } }}>
                Account Pending Review
              </Typography>
            </Grid>{' '}
            <Grid item xs={12}>
              <Typography
                component="div"
                align="center"
                sx={{ fontSize: { xs: '14px', sm: '14px' }, fontWeight: 500, paddingLeft: '30px', paddingRight: '30px' }}
              >
                {cashOutConfig.pendingReviewMessage && cashOutConfig.pendingReviewMessage}
              </Typography>
            </Grid>
          </MessageBox>
        ) : (
          <Loader height="35vh" />
        )}
      </BottomLayout>
    </Layout>
  )
}

export default AccountPendingReview
