import { Box, Grid, Typography } from '@mui/material'
import { MULTIPLIER_BLING_MULTIPLE_COINS } from '../../../../constants'
import MultiplierButton from '../MultiplierButton'

const MultiplierFooter = (props: any) => {
  return (
    <>
      {/* MEDIUM AND ABOVES */}
      <Grid container sx={{ display: { xxs: 'none', md: 'flex', zIndex: 1 } }}>
        <Grid item xs={7}>
          <Box sx={{ position: 'relative' }}>
            <Box
              component="img"
              sx={{
                //   border: 'solid',
                // height: { xxs: '600px' },
                width: '1000px',
                height: '530px',
                marginTop: { sm: '0px', md: '-30px' },
                marginLeft: { md: '-350px', lg: '-250px' },
                paddingRight: '100px',
                position: 'absolute', // Fixes minor gap between the img and bottom of page
              }}
              src={MULTIPLIER_BLING_MULTIPLE_COINS}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={5}
          sx={{ marginTop: '60px', marginBottom: '60px', paddingRight: { lg: '35px' }, paddingLeft: { md: '15px', lg: '0px' } }}
        >
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: { md: '45px' },
              letterSpacing: '0px',
              color: '#FFFFFF',
              opacity: 1,
              paddingBottom: '30px',
              lineHeight: 1.4,
              textTransform: 'uppercase',
            }}
          >
            Start your exclusive multiplier now!
          </Typography>
          <MultiplierButton onClick={props.onClick} />
        </Grid>
      </Grid>

      {/* SMALL AND BELOW */}
      <Grid container sx={{ display: { xxs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center', zIndex: 1 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: { xxs: 'flex', md: 'none' },
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: { xxs: '5px' },
            paddingRight: { xxs: '5px' },
          }}
        >
          <Box
            sx={{
              width: { xs: '350px', sm: '500px' },
            }}
          >
            <Typography
              sx={{
                fontWeight: 900,
                fontSize: { xxs: '20px', xs: '25px', sm: '30px' },
                letterSpacing: '0px',
                color: '#FFFFFF',
                opacity: 1,
                lineHeight: 1.4,
                textTransform: 'uppercase',
                textAlign: 'center',
                paddingTop: '25px',
                paddingBottom: '15px',
              }}
            >
              Start your exclusive multiplier now!
            </Typography>
          </Box>
        </Grid>
        <MultiplierButton onClick={props.onClick} />
      </Grid>
    </>
  )
}

export default MultiplierFooter
