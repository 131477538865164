import { Grid, Typography, Box } from '@mui/material'
import { StyledFirebaseAuth } from 'react-firebaseui'
import { firebaseAuth } from '../../../firebase/firebase'
import { firebaseUIConfig } from '../../../firebase/firebaseUI'
import { BLING_LOGO_WHITE, BLING_LOGO_BLUE, BLING_MULTIPLE_COINS } from '../../../constants'

const leftLayout = {
  background: 'transparent linear-gradient(29deg, #2CDFFF 0%, #175DE4 100%) 0% 0% no-repeat padding-box',
  // background: 'transparent linear-gradient(40deg, #175de4 0%, #2cdfff 100%)',
  opacity: 1,
  minHeight: '100vh',
  display: { xxs: 'none', sm: 'none', md: 'flex' },
  width: { sm: '450px', md: '480px', lg: '500px' },
  // marginRight: { xs: '10px', sm: '0px', md: '10px', lg: '100px', xl: '100px' },
}
const rightLayout = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: { sm: '100%' },
  width: { xxs: '100%', md: '45%' },
  paddingLeft: { sm: '40px', lg: '150px' },
}

const signIn = {
  fontWeight: { xxs: 800, sm: 900 },
  fontSize: { xxs: '30px', md: '24px' },
  minWidth: { xxs: '270px', sm: '280px' },
  marginRight: '40px',
  marginBottom: '18px',
  display: { xxs: 'none', md: 'block' },
}
const blingLogo = {
  color: 'white',
  marginTop: '50px',
  marginBottom: '5px',
  width: { xxs: '110px', md: '80px', lg: '80px' },
  marginLeft: { xxs: '20px', xs: '2px', sm: '75px', md: '50px' },
  marginRight: { xxs: '115px', sm: '190px' },
}
const desc = {
  marginTop: '5px',
  marginLeft: { xxs: '23px', xs: '0px', md: '50px' },
  marginRight: { md: '30px' },
  color: { sm: 'black', md: 'white' },
  fontWeight: { xxs: 800, md: 900 },
  fontSize: { xxs: '16px', sm: '16px', md: '35px' },
  marginBottom: { xxs: '15px', sm: '10px' },
}
const blingCoins = {
  paddingTop: '430px',
  overflow: 'hidden',
  color: 'rgba(0, 0, 0, 0)',
}
const leftTop = {
  paddingBottom: '10px',
}
const leftBottom = {
  flex: 1,
  backgroundImage: `url(${BLING_MULTIPLE_COINS})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}

const Login = () => {
  return (
    <Grid container>
      <Grid item sx={leftLayout}>
        <Grid container>
          <Grid item xs={12} sx={leftTop}>
            <Box component="img" sx={blingLogo} src={BLING_LOGO_WHITE} />
            <Typography sx={desc}>Cash Out Your Bling Points!</Typography>
          </Grid>
          <Grid item xs={12} sx={leftBottom}>
            <Typography variant="h4" sx={blingCoins}></Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={rightLayout}>
        <Grid container sx={{ marginBottom: '50px' }}>
          <Grid
            item
            xs={12}
            sx={{
              display: { xxs: 'flex', md: 'none' },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box component="img" sx={blingLogo} src={BLING_LOGO_BLUE} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: { xxs: 'flex', md: 'none' },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography align="center" sx={desc}>
              Cash Out Your Bling Points!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" sx={signIn}>
              Sign in to Bling
            </Typography>

            <StyledFirebaseAuth uiConfig={firebaseUIConfig} firebaseAuth={firebaseAuth} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Login
