/**
 * User is visiting the Web Portal via WebView
 */
import { Box, Grid, Typography } from '@mui/material'

const WebView = () => {
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
      <Box sx={{ width: '260px' }}>
        <Box component="img" src="https://uploads-ssl.webflow.com/static/page-not-found.211a85e40c.svg" sx={{ width: '256px' }} />
        <Typography
          sx={{
            fontSize: '25px',
            lineHeight: '36px',
            marginTop: '20px',
            fontFamily: 'Nunito Sans',
            fontWeight: 700,
            color: '#333',
            textAlign: 'center',
          }}
        >
          Internal Server Error
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'Nunito Sans',
            textAlign: 'center',
          }}
        >
          Please open a native web browser and sign in from there.
        </Typography>
      </Box>
    </Grid>
  )
}

export default WebView
