import MessageBox from '../Templates/Box/MessageBox'
import AppleIcon from '@mui/icons-material/Apple'
import AndroidIcon from '@mui/icons-material/Android'
import LightningProcessButton from './LightningProcessButton'
import { Grid, Typography, IconButton, Button, Box } from '@mui/material'
import { LightningWallet } from '../../../redux/slices/cashOut'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { useState } from 'react'

const LightningProcess = () => {
  const { cashOutConfig, cashOutButtonDisabled } = useSelector((state: RootState) => state.cashOut)
  const [showCount, setShowCount] = useState(4) // How many wallets to show

  const handleShowMore = () => {
    setShowCount((prevCount) => prevCount + 4)
  }

  return (
    <>
      <MessageBox>
        <Grid item xs={12}>
          <Typography align="center" sx={{ fontWeight: 600, fontSize: { xs: '14px', sm: '16px' } }}>
            Recommended Wallets:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="div"
            align="center"
            sx={{ fontSize: { xs: '12px', sm: '14px' }, fontWeight: 400, paddingLeft: '20px', paddingRight: '20px' }}
          >
            Download one of our recommended Lightning Wallets BEFORE continuing.
          </Typography>
        </Grid>
      </MessageBox>
      <Box sx={{ mb: 2 }}>
        {Object.values(cashOutConfig.lightningWalletWhitelist)
          .slice(0, showCount)
          .map((wallet: LightningWallet) => (
            <Grid
              container
              sx={{ borderRadius: 2, backgroundColor: 'white', borderColor: 'rgba(0,0,0,.)', p: 1.2, mb: 1 }}
              key={wallet.name}
            >
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={wallet.iconUrl} alt={wallet.name} style={{ height: '30px', width: '30px', borderRadius: '50%' }} />
              </Grid>
              <Grid item xs sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                <Typography sx={{ fontWeight: 700, ml: '5px', fontSize: '16px' }}>{wallet.name}</Typography>
              </Grid>

              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid
                  item
                  sx={{
                    backgroundColor: 'white',
                  }}
                >
                  {wallet.iosStoreListingUrl && (
                    <IconButton
                      onClick={() => window.open(wallet.iosStoreListingUrl)}
                      color="primary"
                      sx={{
                        fontWeight: 700,
                        borderRadius: '4px',
                        color: '#FFFFFF',
                        background: 'black',
                        mr: 1,
                        border: 'solid 1px transparent',
                        '&:hover': {
                          color: 'black',
                          background: '#FFFFFF',
                          border: 'solid 1px',
                        },
                      }}
                    >
                      <AppleIcon fontSize="small" />
                    </IconButton>
                  )}
                  {wallet.androidStoreListingUrl && (
                    <IconButton
                      onClick={() => window.open(wallet.androidStoreListingUrl)}
                      color="primary"
                      sx={{
                        fontWeight: 700,
                        borderRadius: '4px',
                        color: '#FFFFFF',
                        background: 'black',
                        border: 'solid 1px transparent',
                        '&:hover': {
                          color: 'black',
                          background: '#FFFFFF',
                          border: 'solid 1px',
                        },
                      }}
                    >
                      <AndroidIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}

        {Object.values(cashOutConfig.lightningWalletWhitelist).length > showCount && (
          <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
            <Button
              variant="text"
              onClick={handleShowMore}
              sx={{ textTransform: 'none', fontWeight: 600, fontSize: { xs: '14px', sm: '16px' } }}
            >
              Show More
            </Button>
          </Grid>
        )}
      </Box>

      <Grid container direction="column" justifyContent="center" alignItems="center" item xs={12} sx={{ mt: 2, mb: 3 }}>
        <Typography align="center" sx={{ fontWeight: 700, width: '60%', mb: 2, fontSize: { xs: '12px', sm: '14px' } }}>
          Continue to lightning cash out after installing a wallet.
        </Typography>
        <LightningProcessButton cashOutButtonDisabled={cashOutButtonDisabled} />
      </Grid>
    </>
  )
}

export default LightningProcess
