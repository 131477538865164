/**
 * Prompt user to enter their recent verification code
 * for their Bling email and/or Coinbase email (cash out email).
 */

import * as yup from 'yup'
import { AppDispatch, RootState } from '../../redux/store'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  setVerifyErrorMessage,
  setVerifyButtonDisabled,
  setLoadingVerification,
  handleVerificationCode,
} from '../../redux/slices/cashOut'
import { VerificationContext } from '../../redux/models'
import TopBanner from './TopBanner'
import Loader from '../../components/CashOut/Loader/Loader'
import CashOutCard from '../../components/CashOut/Templates/Card/CashOutCard'
import VerifyButton from '../../components/CashOut/Verify/VerifyButton'
import VerifyCode from '../../components/CashOut/Verify/VerifyCode'
import VerifyDesc from '../../components/CashOut/Verify/VerifyDesc'
import VerifyEmail from '../../components/CashOut/Verify/VerifyEmail'
import VerifyTitle from '../../components/CashOut/Verify/VerifyTitle'
import BottomLayout from '../../layouts/CashOut/BottomLayout'
import Layout from '../../layouts/CashOut/Layout'

const CashOutVerify = () => {
  const { appDeviceGuid, userAgent, webView } = useSelector((state: RootState) => state.user)
  const {
    loadingVerification,
    loadingCashOutConfig,
    verificationRequired,
    verifyEmail,
    verifyErrorMessage,
    selectedCurrency,
    selectedCashoutPlatform,
  } = useSelector((state: RootState) => state.cashOut)
  const dispatch = useDispatch<AppDispatch>()

  const validationSchema = yup.object({
    code: yup.number().required('Code is required').typeError('Please enter a correct code'),
  })

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const v = values as { code: string }
      // Clear any previous errors
      resetForm()
      dispatch(setVerifyErrorMessage(''))

      // Start the verification process
      dispatch(setVerifyButtonDisabled(true))
      dispatch(setLoadingVerification(true))
      dispatch(
        handleVerificationCode(
          new VerificationContext(
            v.code,
            selectedCurrency.symbol,
            selectedCashoutPlatform.toUpperCase(),
            appDeviceGuid,
            userAgent,
            webView,
          ),
        ),
      )
    },
  })
  return (
    <Layout>
      <TopBanner />
      <BottomLayout>
        {!loadingVerification && !loadingCashOutConfig && verificationRequired ? (
          <CashOutCard>
            <form onSubmit={formik.handleSubmit}>
              <VerifyTitle />
              <VerifyDesc>We sent the code to your email</VerifyDesc>
              <VerifyEmail verifyEmail={verifyEmail} />
              <VerifyCode formik={formik} verifyErrorMessage={verifyErrorMessage} />
              <VerifyDesc paddingTop="10px">Please enter the code to continue</VerifyDesc>
              <VerifyButton />
            </form>
          </CashOutCard>
        ) : (
          <Loader height="35vh" />
        )}
      </BottomLayout>
    </Layout>
  )
}

export default CashOutVerify
