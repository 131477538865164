import { initializeApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { initializeAppCheck, getToken, ReCaptchaV3Provider } from 'firebase/app-check'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { tryReloadPage } from '../utils/reloadPage'

/**
 * Handle Firebase environment (including App Check) using local environment variables
 * to set up the Firebase configuration.
 */
let appCheckEnv = process.env.REACT_APP_FIREBASE_APP_CHECK_KEY
let firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

/**
 * Access calls to cloud functions, authentication, and FirebaseUI
 */
export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseFunctions = getFunctions(firebaseApp)
// unused?
export const firebaseAnalytics = getAnalytics(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseFirestore = getFirestore(firebaseApp)

// Add debug token for local development
if (process.env.REACT_APP_APP_CHECK_DEBUG && process.env.REACT_APP_APP_CHECK_DEBUG === 'true') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

// Firebase Emulator for local testing
if (process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true

  connectAuthEmulator(firebaseAuth, 'http://localhost:9099')
  connectFirestoreEmulator(firebaseFirestore, 'localhost', 8080)
  connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001)
}

// Initialize App Check
initAppCheck()

/**
 * Connect App Check with the public key
 */
async function initAppCheck() {
  try {
    const appCheck = initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaV3Provider(appCheckEnv!),
      isTokenAutoRefreshEnabled: true,
    })
    await getToken(appCheck)
  } catch (error) {
    if (error instanceof Error && error.message.includes('AppCheck') && error.message.includes('403')) {
      tryReloadPage()
    }
  }
}
