import React from 'react'
import TopBanner from './TopBanner'
import Layout from '../../layouts/CashOut/Layout'
import BottomLayout from '../../layouts/CashOut/BottomLayout'
import { CashOutCard } from '../../components/CashOut/Templates/Card/CashOutCard'
import CountryTitle from '../../components/CashOut/Country/CountryTitle'
import CountryDescription from '../../components/CashOut/Country/CountryDescription'
import CountrySelector from '../../components/CashOut/Country/CountrySelector'

/**
 * Whole layout for the Country Select window. Has the top banner, cash out card,
 * country title + description + selector
 * @constructor
 */
const Country = () => {
  return (
    <Layout>
      <TopBanner />
      <BottomLayout>
        <CashOutCard>
          <CountryTitle />
          <CountryDescription />
          <CountrySelector />
        </CashOutCard>
      </BottomLayout>
    </Layout>
  )
}

export default Country
