import { Grid, Box, Typography } from '@mui/material'
import { GAME_ICONS } from '../../../../constants'

const DetailTwo = () => {
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ display: { xxs: 'none', xs: 'flex' }, justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}
      >
        <Box
          sx={{
            background: '#6851AA 0% 0% no-repeat padding-box',
            borderRadius: '12px',
            opacity: 1,
            width: { xxs: '90%', sm: '540px', md: '600px' },
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <Grid container sx={{ display: { xxs: 'none', xs: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={4}>
              <Grid container spacing={0.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={3}>
                  <Box component="img" sx={{ width: '100%' }} src={GAME_ICONS.BITCOIN_BLOCKS} />
                </Grid>
                <Grid item xs={3}>
                  <Box component="img" sx={{ width: '100%' }} src={GAME_ICONS.BITCOIN_SOLITAIRE} />
                </Grid>
                <Grid item xs={3}>
                  <Box component="img" sx={{ width: '100%' }} src={GAME_ICONS.ETHEREUM_BLAST} />
                </Grid>
              </Grid>
              <Grid container spacing={0.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={3}>
                  <Box component="img" sx={{ width: '100%' }} src={GAME_ICONS.BITCOIN_POP} />
                </Grid>
                <Grid item xs={3}>
                  <Box component="img" sx={{ width: '100%' }} src={GAME_ICONS.BITCOIN_SUDOKU} />
                </Grid>
                <Grid item xs={3}>
                  <Box component="img" sx={{ width: '100%' }} src={GAME_ICONS.SWEET_BITCOIN} />
                </Grid>
              </Grid>
              <Grid container spacing={0.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={3}>
                  <Box component="img" sx={{ width: '100%' }} src={GAME_ICONS.BITCOIN_FOOD_FIGHT} />
                </Grid>
                <Grid item xs={3}>
                  <Box component="img" sx={{ width: '100%' }} src={GAME_ICONS.BITCOIN_BLAST} />
                </Grid>
                <Grid item xs={3}>
                  <Box component="img" sx={{ width: '100%' }} src={GAME_ICONS.WORD_BREEZE} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { xxs: '1.1rem', xs: '1.3rem', sm: '1.6rem' },
                  color: '#46FFF8',
                  fontWeight: 900,
                  lineHeight: 1.1,
                  paddingRight: { xs: '10px' },
                }}
              >
                Multiplier is valid for all Bling games
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: { xxs: 'flex', xs: 'none' }, justifyContent: 'center', alignItems: 'center', paddingTop: '30px' }}
      >
        <Box
          sx={{
            background: '#6851AA 0% 0% no-repeat padding-box',
            borderRadius: '12px',
            opacity: 1,
            width: { xxs: '90%' },
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: { xxs: '1.1rem', xs: '1.3rem', sm: '1.6rem' },
                color: '#46FFF8',
                fontWeight: 900,
                lineHeight: 1.1,
              }}
            >
              Your multiplier is valid for all games
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </>
  )
}

export default DetailTwo
