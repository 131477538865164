/**
 * Every cloud function call will have these base context values
 */

import { PLATFORM } from '../../constants'

class BaseContext {
  readonly platform: string
  appdeviceguid: string
  userAgent: string
  webView: boolean

  constructor(appdeviceguid: string, userAgent: string, webView: boolean) {
    this.platform = PLATFORM
    this.appdeviceguid = appdeviceguid
    this.userAgent = userAgent
    this.webView = webView
  }
}

export default BaseContext
