import { Card, CardContent } from '@mui/material'

export const CashOutCard = (props: any) => {
  return (
    <Card
      sx={{
        display: props.display,
        alignItems: props.alignItems,
        justifyContent: props.justifyContent,
        width: {
          xxs: '250px',
          xs: '350px',
          sm: '380px',
          md: '400px',
          lg: '400px',
          xl: '400px',
        },
        height: props.height || '100%',
        borderRadius: '10px',
        marginBottom: '13px',
        opacity: 1,
        boxShadow: '0px 7px 11px #02073424',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        padding: props.padding,
        paddingTop: props.paddingTop,
        paddingLeft: props.paddingLeft,
        paddingRight: props.paddingRight,
      }}
    >
      <CardContent>{props.children}</CardContent>
    </Card>
  )
}

export default CashOutCard
