import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const MinPointsMessage = () => {
  const { cashOutConfig } = useSelector((state: RootState) => state.cashOut)

  return (
    <Grid item xs={12}>
      <Typography align="center" sx={{ fontSize: '20px' }}>
        You need to have at least
      </Typography>
      <Typography align="center" sx={{ fontSize: '20px' }}>
        <b>{cashOutConfig.minRequiredPoints} Bling Points</b> to cash out
      </Typography>
    </Grid>
  )
}

export default MinPointsMessage
