/**
 * Displays the users last cash out details (after Coinbase processes)
 */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import TopBanner from './TopBanner'
import Layout from '../../layouts/CashOut/Layout'
import BottomLayout from '../../layouts/CashOut/BottomLayout'
import CashOutCard from '../../components/CashOut/Templates/Card/CashOutCard'
import LatestCashOutButton from '../../components/CashOut/Latest/LatestCashOutButton'
import LatestCashOutDetails from '../../components/CashOut/Latest/LatestCashOutDetails'
import LatestCashOutMessage from '../../components/CashOut/Latest/LatestCashOutMessage'
import LatestCashOutTitle from '../../components/CashOut/Latest/LatestCashOutTitle'
import LatestCashOutValue from '../../components/CashOut/Latest/LatestCashOutValue'

const LastCashOut = () => {
  const { cashOutConfig } = useSelector((state: RootState) => state.cashOut)

  const [formattedDate, setFormattedDate] = useState<string>('')
  const [timer, setTimer] = useState<string>('')
  const [currencyValue, setCurrencyValue] = useState<string>('')
  const [currencySymbol, setCurrencySymbol] = useState<string>('BTC')

  useEffect(() => {
    // Format the previous cash out date
    const formatFullDate = () => {
      const date = new Date(Number(cashOutConfig.lastCashout.fundsSentAt)).toDateString().split(' ')
      const formattedDate = date[1] + ' ' + date[2] + ', ' + date[3]
      return formattedDate
    }

    // Format the timer for the previous cash out
    const timeBetweenDates = () => {
      const currentDate = new Date().getTime()
      const cashoutDate = new Date(cashOutConfig.nextCashoutAvailableAt).getTime()
      const difference = cashoutDate - currentDate

      var seconds = Math.floor(difference / 1000)
      var minutes = Math.floor(seconds / 60)
      var hours = Math.floor(minutes / 60)
      var days = Math.floor(hours / 24)
      hours %= 24
      minutes %= 60
      seconds %= 60

      return `${days} DAYS ${hours} HRS ${minutes} MIN`
    }

    setTimer(timeBetweenDates())
    setFormattedDate(formatFullDate())

    if (cashOutConfig.lastCashout.cryptoAmount) {
      setCurrencyValue(cashOutConfig.lastCashout.cryptoAmount)
    } else {
      setCurrencyValue(cashOutConfig.amountBtc)
    }

    if (cashOutConfig.lastCashout.cryptoSymbol) {
      setCurrencySymbol(cashOutConfig.lastCashout.cryptoSymbol)
    }
  }, [
    cashOutConfig.amountBtc,
    cashOutConfig.lastCashout.cryptoAmount,
    cashOutConfig.lastCashout.cryptoSymbol,
    cashOutConfig.lastCashout.fundsSentAt,
    cashOutConfig.nextCashoutAvailableAt,
  ])

  return (
    <Layout>
      <TopBanner />
      <BottomLayout>
        <CashOutCard>
          <LatestCashOutTitle />
          <LatestCashOutValue currencyValue={currencyValue} currencySymbol={currencySymbol} />
          <LatestCashOutDetails
            formattedDate={formattedDate}
            cashOutEmail={cashOutConfig.lastCashout.cashoutEmail}
            transferVia={cashOutConfig.lastCashout.transferVia}
          />
          <LatestCashOutMessage />
        </CashOutCard>
        <LatestCashOutButton timer={timer} />
      </BottomLayout>
    </Layout>
  )
}

export default LastCashOut
