import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { BLING_LOGO_BLUE, BLING_LOGO_WHITE } from '../../../../constants'

const BlingLogo = (props: any) => {
  return (
    <Grid item xs={12}>
      {!props.blueLogo ? (
        <Box component="img" sx={{ width: '100px', marginLeft: '20px' }} src={BLING_LOGO_WHITE} />
      ) : (
        <Box component="img" sx={{ width: '200px', marginLeft: '20px', marginTop: '20px' }} src={BLING_LOGO_BLUE} />
      )}
    </Grid>
  )
}

export default BlingLogo
