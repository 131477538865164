import { Button, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const MultiplierButton = (props: any) => {
  const { multiplierButtonDisabled } = useSelector((state: RootState) => state.multiplier)

  // TODO: Font family for Apply text?
  return (
    <Button
      color="primary"
      variant="contained"
      type="submit"
      onClick={(e) => props.onClick(e)}
      disabled={multiplierButtonDisabled}
      sx={{
        width: '260px',
        height: '70px',
        background: 'transparent linear-gradient(255deg, #FF36C1 0%, #FDC038 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 0px #00000029',
        borderRadius: '8px',
        opacity: 1,
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: 900,
          fontSize: '33px',
          letterSpacing: '1.32px',
          color: '#EFEBFF',
          textTransform: 'uppercase',
          opacity: 1,
        }}
      >
        Activate
      </Typography>
    </Button>
  )
}

export default MultiplierButton
