/**
 * Browser cookie functions
 */
import Cookies from 'js-cookie'

export const setCookie = (name: string, value: string): string | undefined => {
  return Cookies.set(name, value)
}

export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name)
}

export const removeCookie = (name: string): void => {
  return Cookies.remove(name)
}
