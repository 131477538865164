import { Grid, Button } from '@mui/material'

const ProcessButton = (props: any) => {
  return (
    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={props.cashOutButtonDisabled}
        sx={{
          border: '1px solid #ffea92',
          width: { xxs: '220px', xs: '300px', sm: '320px' },
          height: '60px',
          borderRadius: '8px',
          color: '#FFFFFF;',
          background: 'linear-gradient(195deg, #ffce00, #ffb700 30%, #ff7300)',
          boxShadow: '0px 3px 0px #00000029',
          fontWeight: 900,
          marginTop: '10px',
          fontSize: '1.5rem',
        }}
      >
        CASH OUT
      </Button>
    </Grid>
  )
}

export default ProcessButton
