import { FC } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Navigate, Route, Routes } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import NotFound from './components/Errors/NotFound'
import Success from './pages/CashOut/Success'
import PlatformType from './pages/CashOut/Platform'
import CurrencyType from './pages/CashOut/Currency'
import Coinbase from './pages/CashOut/Coinbase'
import LatestCashOut from './pages/CashOut/Latest'
import Process from './pages/CashOut/Process'
import MinPoints from './pages/CashOut/MinPoints'
import AuthStatusRoute from './routes/AuthStatusRoute'
import LoadingRoute from './routes/LoadingRoute'
import ProtectedRoute from './routes/ProtectedRoute'
import Verify from './pages/CashOut/Verify'
import CashOutRoute from './routes/CashOutRoute'
import Unique from './pages/Multiplier/Unique'
import UniqueMultiplier from './routes/Multiplier/UniqueMultiplier'
import LoadingMultiplier from './routes/Multiplier/LoadingMultiplier'
import Login from './components/CashOut/Login/Login'
import PendingLightning from './pages/CashOut/PendingLightning'
import AccountPendingReview from './pages/CashOut/AccountPendingReview'
import AlertManager from './alerts/AlertManager'
import Country from './pages/CashOut/Country'

// Module augmentation needed for customizing MUI breakpoints with Typescript
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
  }
}

// Override overall default theme font and customize breakpoints
const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
    h2: {
      fontWeight: 700, // Bold
    },
  },
  breakpoints: {
    values: {
      xxs: 0, // For devices like the Galaxy Fold
      xs: 280,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
})

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AlertManager />
      <CssBaseline />
      <Routes>
        {/* Unknown Route */}

        <Route path="*" element={<NotFound />} />

        {/* Requires Login */}
        <Route element={<AuthStatusRoute />}>
          <Route element={<LoadingRoute />}>
            <Route element={<ProtectedRoute />}>
              <Route path="" element={<Navigate to="/login" replace />} />
              <Route path="login" element={<Login />} />

              {/* Cash Out Route */}
              <Route path="cash-out" element={<CashOutRoute />}>
                <Route path="platform" element={<PlatformType />} />
                <Route path="currency" element={<CurrencyType />} />
                <Route path="coinbase" element={<Coinbase />} />
                <Route path="latest" element={<LatestCashOut />} />
                <Route path="minimum-points" element={<MinPoints />} />
                <Route path="process" element={<Process />} />
                <Route path="verification" element={<Verify />} />
                <Route path="country" element={<Country />} />
                <Route path="success" element={<Success />} />
                <Route path="pending-lightning" element={<PendingLightning />} />
                <Route path="account-pending-review" element={<AccountPendingReview />} />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* Multiplier Route */}
        <Route element={<UniqueMultiplier />}>
          <Route element={<LoadingMultiplier />}>
            <Route path="multiplier/:id" element={<Unique />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  )
}

export default App
