import { Box } from '@mui/material'
import { BLING_POINTS_COIN } from '../../../constants'

const BlingPointsCoin = () => {
  return (
    <Box
      component="img"
      sx={{
        width: { xs: '50px', sm: '70px' },
        opacity: 1,
        height: { xxs: '50px', xs: '70px', sm: '90px' },
        transform: 'scaleX(-1)',
      }}
      src={BLING_POINTS_COIN}
    />
  )
}

export default BlingPointsCoin
