import { SxProps, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { customSxProps } from '../../../../utils'

const lteSmall: SxProps = {
  paddingBottom: { xxs: '40px', xs: '80px' },
  paddingTop: '5px',
  textAlign: 'center',
  fontSize: { xxs: '1rem', sm: '1.3rem' },
}

const gteMedium: SxProps = {
  paddingTop: '5px',
  paddingBottom: '80px',
  fontSize: { xxs: '14px', sm: '18px' },
}

interface ExpiresProps {
  smallerDevices?: boolean
}

const Expires = (props: ExpiresProps): React.ReactElement => {
  const { tokenExpiresDate, tokenExpiresTime } = useSelector((state: RootState) => state.multiplier)

  return (
    <Typography
      sx={[
        {
          fontWeight: 900,
          opacity: 1,
          lineHeight: 1.4,
          textShadow: '0px 3px 1px #00000029;',
          color: ' #F2F6FA80',
        },
        ...customSxProps(props.smallerDevices ? lteSmall : gteMedium),
      ]}
    >
      Offer expires {tokenExpiresTime} PST on {tokenExpiresDate}
    </Typography>
  )
}

export default Expires
