import { Grid, Typography } from '@mui/material'

const CountryDescription = () => {
  return (
    <Grid item xs={12}>
      <Typography
        align="center"
        color="textPrimary"
        sx={{
          marginTop: '25px',
          marginBottom: '25px',
        }}
      >
        Please select your country of residence.
      </Typography>
    </Grid>
  )
}

export default CountryDescription
