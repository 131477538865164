/**
 * Prompts the user to select a platform to cash out to
 */
import { AppDispatch, RootState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { setSelectedCashoutPlatform, setSelectedCurrency } from '../../redux/slices/cashOut'
import NavBar from '../../components/CashOut/Navbar/NavBar'
import PlatformButton from '../../components/CashOut/Platform/PlatformButton'
import PlatformDesc from '../../components/CashOut/Platform/PlatformDesc'

const Platform = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { cashOutConfig } = useSelector((state: RootState) => state.cashOut)

  const onSubmit = (e: Record<string, unknown>) => {
    const currentTarget = e.currentTarget as { name: string; value: string }

    dispatch(setSelectedCashoutPlatform(currentTarget.name))

    if (currentTarget.name.toLowerCase() === 'lightning') {
      const bitcoin = cashOutConfig.currencies.bitcoin
      dispatch(setSelectedCurrency(bitcoin))
    }
  }

  const PlatformTypeButtons = () => {
    const buttons: React.ReactElement[] = []

    if (cashOutConfig && cashOutConfig.platforms) {
      for (const [key, value] of Object.entries(cashOutConfig.platforms)) {
        buttons.push(
          <Grid
            key={key}
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PlatformButton name={value.name} value={value} onSubmit={onSubmit} />
          </Grid>,
        )
      }
    }

    return buttons
  }

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" minWidth="280px">
      <NavBar color="black" blueLogo="true" currency="true" />
      <Grid item xs={12}>
        <PlatformDesc />
        {PlatformTypeButtons()}
      </Grid>
    </Grid>
  )
}

export default Platform
