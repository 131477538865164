import { Grid, TextField } from '@mui/material'

const ProcessEmail = (props: any) => {
  return (
    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
      <TextField
        // fullWidth
        id="email"
        name="email"
        label="Email"
        variant="outlined"
        autoFocus
        value={props.formik.values.email}
        onChange={props.formik.handleChange}
        error={(props.formik.touched.email && Boolean(props.formik.errors.email)) || props.cashOutErrorMessage ? true : false} // If formik error or custom error
        helperText={(props.formik.touched.email && props.formik.errors.email) || props.cashOutErrorMessage} // Display validation or custom error messsage
        inputProps={{ min: 0 }}
        sx={{ width: '90%', marginTop: '12px' }}
      />
    </Grid>
  )
}

export default ProcessEmail
