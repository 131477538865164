import { Typography } from '@mui/material'

const VerifyDesc = (props: any) => {
  return (
    <Typography align="center" sx={{ paddingTop: props.paddingTop, fontSize: '15px' }}>
      {props.children}
    </Typography>
  )
}

export default VerifyDesc
