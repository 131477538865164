/**
 * Checks authentication, otherwise navigate to the correct route
 */

import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { RootState } from '../redux/store'
import Login from '../components/CashOut/Login/Login'
import WebView from '../components/Errors/WebView'

const ProtectedRoute = () => {
  const { webView } = useSelector((state: RootState) => state.user)
  const { authenticated } = useSelector((state: RootState) => state.auth)

  const location = useLocation()

  if (webView && !process.env.REACT_APP_APP_CHECK_DEBUG) return <WebView /> // We don't want WebView
  if (authenticated && location && location.pathname === '/login') return <Navigate to="cash-out" /> // Avoid ProtectedRoute loop since login path is nested inside
  if (!authenticated && location && location.pathname === '/login') return <Login /> // Avoid ProtectedRoute loop since login path is nested inside
  if (!authenticated || (location && location.pathname === '')) return <Navigate to="login" /> // Navigate to the login page flow
  return <Outlet /> // If user authenticated then redirect to correct page using redux state
}

export default ProtectedRoute
