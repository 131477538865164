import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const ProcessHeader = () => {
  const { selectedCashoutPlatform } = useSelector((state: RootState) => state.cashOut)

  return (
    <Box
      sx={{
        height: '70px',
        marginBottom: '15px',
        background: '#0467D7',
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          color: '#FFFFFF',
          padding: '18px',
          fontSize: '26px',
        }}
      >
        {selectedCashoutPlatform.toLowerCase() === 'coinbase' ? selectedCashoutPlatform.toLowerCase() : selectedCashoutPlatform}
      </Typography>
    </Box>
  )
}

export default ProcessHeader
