import { Card, CardContent } from '@mui/material'
import { Props } from '../../../../types'

const ConversionCard = ({ children }: Props) => {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        top: { xxs: '40px', xs: '40px', sm: '42px' },
        paddingTop: '5px',
        marginTop: { xxs: '-20px', xs: '-35px', sm: '-25px' }, // Adjust blue top banner "height"
        height: '85px',
        border: '1px solid #1EA0F0',
        borderRadius: '10px',
        width: {
          xxs: '220px',
          xs: '280px',
          sm: '320px',
          md: '360px',
          lg: '360px',
          xl: '360px',
        },
      }}
    >
      <CardContent>{children}</CardContent>
    </Card>
  )
}

export default ConversionCard
