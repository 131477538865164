/**
 * Prompts new users to select whether they have an existing Coinbase account or not
 */

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store'
import { setSelectedCoinbase } from '../../redux/slices/cashOut'
import TopBanner from './TopBanner'
import Layout from '../../layouts/CashOut/Layout'
import BottomLayout from '../../layouts/CashOut/BottomLayout'
import CashOutCard from '../../components/CashOut/Templates/Card/CashOutCard'
import CoinbaseButton from '../../components/CashOut/Coinbase/CoinbaseButton'
import CoinbaseDesc from '../../components/CashOut/Coinbase/CoinbaseDesc'
import CoinbaseTitle from '../../components/CashOut/Coinbase/CoinbaseTitle'

const CoinbaseStatus = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { selectedCashoutPlatform } = useSelector((state: RootState) => state.cashOut)

  const onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === 'newCoinbaseAccount') {
      dispatch(
        setSelectedCoinbase({
          selected: true,
          option: 'newCoinbaseAccount',
          description: 'New Coinbase Account',
        }),
      )
    }
    if (e.currentTarget.value === 'existingCoinbaseAccount') {
      dispatch(
        setSelectedCoinbase({
          selected: true,
          option: 'existingCoinbaseAccount',
          description: 'Existing Coinbase Account',
        }),
      )
    }
  }

  return (
    <Layout>
      <TopBanner />
      <BottomLayout>
        <CashOutCard paddingLeft="5px" paddingRight="5px">
          <CoinbaseTitle selectedCashoutPlatform={selectedCashoutPlatform} />
          <CoinbaseDesc selectedCashoutPlatform={selectedCashoutPlatform} />
          <CoinbaseButton value="newCoinbaseAccount" background="#0467d7" marginTop="15px" onSubmit={onSubmit}>
            Create a {selectedCashoutPlatform} Account
          </CoinbaseButton>
          <CoinbaseButton
            value="existingCoinbaseAccount"
            color="#0467d7"
            background="#FFFFFF"
            border="2px solid #0087FF"
            marginTop="10px"
            onSubmit={onSubmit}
          >
            I have a {selectedCashoutPlatform} Account
          </CoinbaseButton>
        </CashOutCard>
      </BottomLayout>
    </Layout>
  )
}

export default CoinbaseStatus
