import Loader from '../components/CashOut/Loader/Loader'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { RootState } from '../redux/store'
import { useEffect, useState } from 'react'

const LoadingRoute = () => {
  const { loadingAuth } = useSelector((state: RootState) => state.auth)
  const { loadingConfigs } = useSelector((state: RootState) => state.user)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!loadingAuth && !loadingConfigs.userRewardConfig && !loadingConfigs.cashOutConfig) setLoading(false)
  }, [loadingAuth, loadingConfigs.cashOutConfig, loadingConfigs.userRewardConfig])

  return loading ? <Loader /> : <Outlet />
}

export default LoadingRoute
