import { Grid, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const VerifyButton = () => {
  const { verifyButtonDisabled } = useSelector((state: RootState) => state.cashOut)

  return (
    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
      <Button
        variant="contained"
        type="submit"
        disabled={verifyButtonDisabled}
        sx={{
          width: '90%',
          height: '50px',
          fontWeight: 800,
          background: 'transparent linear-gradient(180deg, #2CD0E9 0%, #006CFF 100%) 0% 0% no-repeat padding-box;',
          borderRadius: '8px',
          color: 'white',
          '&.Mui-disabled': {
            color: 'gray',
          },
          marginTop: '4px',
          fontSize: '16px',
        }}
      >
        VERIFY
      </Button>
    </Grid>
  )
}

export default VerifyButton
