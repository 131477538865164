import { Grid, Typography } from '@mui/material'

const LatestCashOutValue = (props: any) => {
  return (
    <Grid item xs={12}>
      <Typography
        align="center"
        sx={{
          color: '#00BCD8',
          fontWeight: 700,
          paddingTop: { xs: '18px', sm: '18px' },
          fontSize: { xs: '20px', sm: '20px' },
        }}
      >
        {props.currencySymbol === 'USD' && `\u0024`}
        {props.currencyValue} {props.currencySymbol}
      </Typography>
    </Grid>
  )
}

export default LatestCashOutValue
