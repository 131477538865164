import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const LatestCashOutMessage = () => {
  const { cashOutConfig } = useSelector((state: RootState) => state.cashOut)

  return (
    <Grid item xs={12}>
      <Typography
        align="center"
        sx={{
          borderRadius: '8px',
          background: '#F2F6FA 0% 0% no-repeat padding-box',
          fontWeight: 500,
          color: '#727272',
          fontSize: '14px',
          padding: '20px',
          marginTop: '30px',
        }}
      >
        {cashOutConfig.lastCashout.transferVia.toLowerCase() === 'lightning'
          ? `You will receive an email from Bling about your lightning transfer.`
          : `You will receive an email from ${
              cashOutConfig && cashOutConfig.lastCashout && cashOutConfig.lastCashout.transferVia
            } with
        a link to complete the process.`}
      </Typography>
    </Grid>
  )
}

export default LatestCashOutMessage
