import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const ConversionValue = (props: any) => {
  const { blingPointsBTCValue } = useSelector((state: RootState) => state.user)
  const { selectedCurrency } = useSelector((state: RootState) => state.cashOut)

  return (
    <Grid item xs={12}>
      <Typography color="textPrimary" align="center" sx={{ fontWeight: 400, fontSize: { xs: '16px', sm: '20px' } }}>
        {/* After a cash out we default to the Bitcoin value */}
        {selectedCurrency && selectedCurrency.name && selectedCurrency.name.toUpperCase() !== 'BITCOIN'
          ? selectedCurrency.name.toUpperCase()
          : `${blingPointsBTCValue} BTC`}
      </Typography>
    </Grid>
  )
}

export default ConversionValue
