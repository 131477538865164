import { Button } from '@mui/material'

const CoinbaseButton = (props: any) => {
  return (
    <Button
      value={props.value}
      variant="contained"
      type="submit"
      onClick={(e) => props.onSubmit(e)}
      sx={{
        background: props.background,
        color: props.color,
        border: props.border,
        marginTop: props.marginTop,
        fontWeight: 700,
        height: '55px',
        borderRadius: '9px',
        width: '100%',
        '&:hover': {
          background: props.background,
        },
      }}
    >
      {props.children}
    </Button>
  )
}

export default CoinbaseButton
