/**
 * Context data that the backend will receive via the verification code cloud function
 */

import { BaseContext } from '.'

class VerificationContext extends BaseContext {
  code: string
  currency: string
  transferVia: string

  constructor(code: string, currency: string, transferVia: string, appdeviceguid: string, userAgent: string, webView: boolean) {
    super(appdeviceguid, userAgent, webView)
    this.code = code
    this.currency = currency
    this.transferVia = transferVia
  }
}

export default VerificationContext
