import { SxProps, Typography } from '@mui/material'
import { customSxProps } from '../../../../utils'

const lteSmallTop: SxProps = {
  textAlign: 'center',
  paddingTop: '10px',
  fontSize: { xxs: '2.5rem', xs: '2.5rem', sm: '3.8rem', md: '4rem' },
}

const lteSmallBottom: SxProps = {
  textAlign: 'center',
  fontSize: { xxs: '2.5rem', xs: '2.5rem', sm: '3.8rem', md: '4rem' },
}

const gteMedium: SxProps = {
  fontSize: { md: '4rem' },
}

interface TitleProps {
  lteSmallTop?: boolean
  lteSmallBottom?: boolean
  gteMedium?: boolean
  children: React.ReactNode
}

const TitleText = (props: TitleProps): JSX.Element => {
  return (
    <Typography
      sx={[
        {
          fontWeight: 900,
          color: '#FFFFFF',
          opacity: 1,
          lineHeight: 1.3,
        },
        ...customSxProps(
          props.lteSmallTop ? lteSmallTop : props.lteSmallBottom ? lteSmallBottom : props.gteMedium ? gteMedium : null,
        ),
      ]}
    >
      {props.children}
    </Typography>
  )
}

export default TitleText
