/**
 * Prompts the user to select a cryptocurrency.
 */
import { AppDispatch, RootState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { setSelectedCurrency } from '../../redux/slices/cashOut'
import CurrencyButton from '../../components/CashOut/Currency/CurrencyButton'
import CurrencyDesc from '../../components/CashOut/Currency/CurrencyDesc'
import NavBar from '../../components/CashOut/Navbar/NavBar'

// import BitcoinLogo from '../images/bitcoin_logo.png';
// import EthereumLogo from '../images/ethereum_logo.svg';
// import TopBanner from './TopBanner';
// import Layout from '../layouts/Layout';
// import BottomLayout from '../layouts/BottomLayout';
// import CashOutCard from '../components/Templates/Card/CashOutCard';
// import BlingPointsText from '../components/BlingPoints/BlingPointsText';
// import BlingPointsValue from '../components/BlingPoints/BlingPointsValue';
// import ConversionText from '../components/Conversion/ConversionText';
// import ConversionType from '../components/Conversion/ConversionType';
// import ConversionValue from '../components/Conversion/ConversionValue';
// import NavBar from '../components/Navbar/NavBar';
// import ConversionCard from '../components/Templates/Card/ConversionCard';
// import TopLayout from '../layouts/TopLayout';

const Currency = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { cashOutConfig } = useSelector((state: RootState) => state.cashOut)
  const { selectedCashoutPlatform } = useSelector((state: RootState) => state.cashOut)

  const onSubmit = (e: Record<string, unknown>) => {
    const currentTarget = e.currentTarget as { name: string; value: string }
    const selectedCurrency = {
      name: currentTarget.name,
      symbol: currentTarget.value,
      decimalPlaces: 0,
      imageUrl: '',
    }

    dispatch(setSelectedCurrency(selectedCurrency))
  }

  const CurrencyTypeButtons = () => {
    const buttons: React.ReactElement[] = []
    for (const [currencyKey, currency] of Object.entries(cashOutConfig.currencies)) {
      if (!cashOutConfig.platforms[selectedCashoutPlatform.toLowerCase()].currencies.includes(currencyKey.toUpperCase())) continue
      buttons.push(
        <Grid
          key={currencyKey}
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CurrencyButton name={currency.name} value={currency.symbol} onSubmit={onSubmit} />
        </Grid>,
      )
    }
    return buttons
  }

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" minWidth="280px">
      <NavBar color="black" blueLogo="true" currency="true" />
      <Grid item xs={12}>
        <CurrencyDesc />
        {CurrencyTypeButtons()}
      </Grid>
    </Grid>
  )
}

export default Currency
