import { Typography } from '@mui/material'

const ProcessTitle = () => {
  return (
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: '18px',
        paddingLeft: '18px',
        paddingRight: '18px',
      }}
    >
      Time to cash out!
    </Typography>
  )
}

export default ProcessTitle
