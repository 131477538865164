/**
 * Container for the BlingLogo and Logout components
 */

import { Grid } from '@mui/material'
import BlingLogo from './BlingLogo'
import Logout from './Logout'

const NavBar = (props: any) => {
  return (
    <Grid container paddingBottom="5px" minWidth="280px" maxWidth="1920px">
      <Grid item xs={6}>
        <BlingLogo blueLogo={props.blueLogo} currency={props.currency} />
      </Grid>
      <Grid item xs={6}>
        <Logout color={props.color} />
      </Grid>
    </Grid>
  )
}

export default NavBar
