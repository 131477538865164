/**
 * Used to display simple messages to the user in the bottom layout
 * without displaying a visible container.
 */

import { Box } from '@mui/material'

const MessageContainer = (props: any) => {
  return (
    <Box
      sx={{
        width: {
          xxs: '100%',
          xs: '320px',
          sm: '320px',
          md: '360px',
          lg: '360px',
          xl: '360px',
        },
        pb: '20px',
      }}
    >
      {props.children}
    </Box>
  )
}

export default MessageContainer
