import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { BaseContext } from '../../../redux/models'
import { cancelLightningCashOut } from '../../../redux/slices/cashOut'
import { AppDispatch } from '../../../redux/store'

interface CancelLightningDialogProps {
  baseContext: BaseContext
  cashOutId: string
  openCancelDialog: boolean
  setOpenCancelDialog: React.Dispatch<React.SetStateAction<boolean>>
}

export const CancelLightningDialog = (props: CancelLightningDialogProps) => {
  const { baseContext, cashOutId, openCancelDialog, setOpenCancelDialog } = props
  const dispatch = useDispatch<AppDispatch>()

  const cancelCashOut = useCallback(() => {
    setOpenCancelDialog(false)
    dispatch(cancelLightningCashOut({ baseContext, cashOutId }))
  }, [baseContext, cashOutId, dispatch, setOpenCancelDialog])

  return (
    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" mt={5}>
      <Button onClick={() => setOpenCancelDialog(true)} disabled={openCancelDialog}>
        Cancel
      </Button>

      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your points will be immediately refunded, and you can initiate a new cash out at any time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)}>No</Button>
          <Button onClick={cancelCashOut} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
