import { SxProps, Typography } from '@mui/material'
import { customSxProps } from '../../../../../utils'

const lteSmall: SxProps = {
  fontSize: { xxs: '95px', sm: '145px' },
}

const gteMediumX: SxProps = {
  fontSize: { md: '200px' },
}

const gteMediumValue: SxProps = {
  fontSize: { md: '230px' },
  position: 'absolute',
  marginTop: '-25px',
}

interface MultiplierProps {
  lteSmall?: boolean
  gteMediumX?: boolean
  gteMediumValue?: boolean
  children: React.ReactNode
}

const Multiplier = (props: Partial<MultiplierProps>): JSX.Element => {
  return (
    <Typography
      sx={[
        {
          fontWeight: 900,
          fontFamily: 'FatFrank',
          color: '#FFFFFF',
          opacity: 1,
          textShadow: '0px -1px 0.5px #C657E58B',
          display: 'inline',
        },
        ...customSxProps(
          props.lteSmall ? lteSmall : props.gteMediumX ? gteMediumX : props.gteMediumValue ? gteMediumValue : null,
        ),
      ]}
    >
      {props.children}
    </Typography>
  )
}

export default Multiplier
