import { Box } from '@mui/material'
import { BLING_CROWN } from '../../../../../constants'

const BlingCrown = (): JSX.Element => {
  return (
    <Box
      component="img"
      sx={{
        width: { xxs: '90px', sm: '150px', md: '200px' },
        marginBottom: { xxs: '110px', sm: '160px', md: '250px' },
        marginLeft: { xxs: '-145px', sm: '-225px', md: '-295px' },
      }}
      src={BLING_CROWN}
    />
  )
}

export default BlingCrown
