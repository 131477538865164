import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { hideAlert } from '../redux/slices/alert'
import { RootState, AppDispatch } from '../redux/store'

const AlertManager: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const alert = useSelector((state: RootState) => state.alert)

  if (!alert.showAlert) {
    return null
  }

  const handleAction = () => {
    dispatch(hideAlert())
  }

  return (
    <Dialog open={alert.showAlert} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{alert.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAction} color="primary" autoFocus>
          Okay!
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertManager
