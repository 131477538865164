import { Box, Button } from '@mui/material'

/**
 * The UI for one of the countries that shows up as part of the list of countries when searching for a country.
 * @param props
 * @constructor
 */
const CountryButton = (props: any) => {
  return (
    <Button
      variant="contained"
      type="button"
      sx={{
        width: '100%',
        height: '35px',
        marginBottom: '0px',
        padding: '0px',
        color: 'black',
        fontWeight: 400,
        fontSize: '16px',
        background: '#FFFFFF',
        borderRadius: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'left',
        boxShadow: 'none',
        border: '0.5px solid #E0E0E0',
      }}
      onClick={() => props.onSubmit(props.value)}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          paddingLeft: '15px',
          textTransform: 'none',
        }}
      >
        <Box>{props.name}</Box>
        <Box sx={{ paddingRight: '15px' }}>{props.value}</Box>
      </Box>
    </Button>
  )
}

export default CountryButton
