import { Grid, Typography } from '@mui/material'

const BlingPointsText = () => {
  return (
    <Grid item xs={12}>
      <Typography sx={{ color: '#FFFFFF', fontSize: '15px', fontWeight: 600 }}>YOUR BLING POINTS</Typography>
    </Grid>
  )
}

export default BlingPointsText
