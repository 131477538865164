import Loader from '../../components/CashOut/Loader/Loader'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { RootState } from '../../redux/store'

const LoadingMultiplier = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const { loadingMultiplier, multiplierStatus, uniqueMultiplierExists, uniqueMultiplierExpired } = useSelector(
    (state: RootState) => state.multiplier,
  )

  useEffect(() => {
    // Continue loading if multiplier is already activated until redirect
    if (!loadingMultiplier && uniqueMultiplierExists && uniqueMultiplierExpired) {
      window.location.replace(`https://www.blingfi.com/activated?status=expired`)
    } else if (multiplierStatus && multiplierStatus === 'ACTIVATED') {
      window.location.replace(`https://www.blingfi.com/activated?status=used`)
    } else if (!loadingMultiplier && !uniqueMultiplierExists) {
      window.location.replace(`https://www.blingfi.com/activated?status=notfound`)
    } else if (!loadingMultiplier) {
      setLoading(false)
    }
  }, [loadingMultiplier, multiplierStatus, uniqueMultiplierExists, uniqueMultiplierExpired])

  return loading ? <Loader /> : <Outlet />
}

export default LoadingMultiplier
