import Title from './Title'
import DetailsLayout from '../../../../layouts/Multiplier/Details'
import Description from './Description'
import DetailOne from './DetailOne'
import DetailTwo from './DetailTwo'
import DetailThree from './DetailThree'
import Disclaimer from './Disclaimer'

const Details = () => {
  return (
    <DetailsLayout>
      <Title />
      <Description />
      <DetailOne />
      <DetailTwo />
      <DetailThree />
      <Disclaimer />
    </DetailsLayout>
  )
}

export default Details
