/**
 * Context data that the backend will receive via the cash out process cloud function
 */

import { BaseContext } from '.'

class CashOutContext extends BaseContext {
  email: string
  currency: string
  transferVia: string

  constructor(email: string, currency: string, transferVia: string, appdeviceguid: string, userAgent: string, webView: boolean) {
    super(appdeviceguid, userAgent, webView)
    this.email = email
    this.currency = currency
    this.transferVia = transferVia
  }
}

export default CashOutContext
