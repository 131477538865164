import { Typography } from '@mui/material'

const VerifyTitle = () => {
  return (
    <Typography align="center" sx={{ fontWeight: 700, fontSize: '20px', paddingBottom: '10px' }}>
      Verification Code
    </Typography>
  )
}

export default VerifyTitle
