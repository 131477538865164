import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { httpsCallable } from 'firebase/functions'
import { firebaseFunctions } from '../../firebase/firebase'
import { formatDate } from '../../utils'

interface MultiplierToken {
  status: string
  createdAtMs: number
  expiresAtMs: number
  duration: number
  multiplier: number
}

interface MultiplierSliceState {
  multiplierStatus: string
  tokenExpiresDate: string
  tokenExpiresTime: string
  tokenExpiresDays: number
  durationDays: number
  multiplier: number
  multiplierError: boolean
  multiplierButtonDisabled: boolean
  uniqueMultiplierExists: boolean
  uniqueMultiplierActivated: boolean
  uniqueMultiplierExpired: boolean
  loadingMultiplier: boolean
  decimalMultiplier: boolean
}

const initialState: MultiplierSliceState = {
  multiplierStatus: '',
  tokenExpiresDate: '',
  tokenExpiresTime: '',
  tokenExpiresDays: 0,
  durationDays: 0,
  multiplier: 1,
  multiplierError: false,
  multiplierButtonDisabled: false,
  uniqueMultiplierExists: false,
  uniqueMultiplierActivated: false,
  uniqueMultiplierExpired: false,
  loadingMultiplier: true,
  decimalMultiplier: false,
}

// Cloud function that applies the unique multiplier given the multilier id.
export const applyUniqueMultiplier = createAsyncThunk(
  'applyUniqueMultiplier',
  async (multiplierId: string | undefined, { dispatch }) => {
    dispatch(setLoadingMultiplier(true))

    if (!multiplierId) {
      throw new Error('Missing multiplier ID.')
    }

    const response = httpsCallable(firebaseFunctions, 'applyUniqueMultiplier')
    await response({ multiplierId: multiplierId, platform: 'Web' })
      .then(() => {
        dispatch(setUniqueMultiplierActivated(true))
        dispatch(setMultiplierButtonDisabled(false))
      })
      .catch((err) => {
        dispatch(setMultiplierError(true))
        dispatch(setLoadingMultiplier(false))
      })
  },
)

// Cloud function that checks if a unique multiplier is available given the multiplier id.
export const uniqueMultiplierAvailable = createAsyncThunk(
  'uniqueMultiplierAvailable',
  async (multiplierId: string | undefined, { dispatch }) => {
    if (!multiplierId) {
      throw new Error('Missing multiplier ID.')
    }

    const response = httpsCallable(firebaseFunctions, 'uniqueMultiplierAvailable')
    return await response({ multiplierId: multiplierId, platform: 'Web' })
      .then((result) => {
        const data = result.data as MultiplierToken
        if (!data) {
          dispatch(setUniqueMultiplierExists(false))
          dispatch(setLoadingMultiplier(false))
          return false
        }

        const status = data.status
        const createdAtMs = data.createdAtMs
        const expiresAtMs = data.expiresAtMs
        const duration = data.duration
        const multiplier = data.multiplier

        // If multiplier is a decimal then we want the multiplier value image to round it down
        if (multiplier % 1 !== 0) dispatch(setDecimalMultiplier(true))

        // Check if the multiplier has already expired
        const multiplierExpired = new Date(expiresAtMs) < new Date()

        // Set the duration in days
        const durationDays = Math.ceil(duration / (1000 * 60 * 60 * 24))

        // Set the token expiration via time and date
        const tokenExpirationDiff = expiresAtMs - createdAtMs
        const tokenExpirationDays = Math.ceil(tokenExpirationDiff / (1000 * 60 * 60 * 24))
        const tokenExpiresDate = formatDate(new Date(expiresAtMs))
        const tokenExpiresTime = new Date(expiresAtMs).toLocaleTimeString('en-US', { hourCycle: 'h23' })

        dispatch(setMultiplierStatus(status))
        dispatch(setTokenExpiresDate(tokenExpiresDate))
        dispatch(setTokenExpiresTime(tokenExpiresTime))
        dispatch(setTokenExpiresDays(tokenExpirationDays))
        dispatch(setDurationDays(durationDays))
        dispatch(setMultiplier(multiplier))
        dispatch(setUniqueMultiplierExists(true))
        dispatch(setUniqueMultiplierExpired(multiplierExpired))
        dispatch(setLoadingMultiplier(false))
        return true
      })
      .catch((err) => {
        dispatch(setLoadingMultiplier(false))
        dispatch(setUniqueMultiplierExists(false))
      })
  },
)

export const multiplierSlice = createSlice({
  name: 'multiplier',
  initialState,
  reducers: {
    setMultiplierStatus: (state, action: PayloadAction<string>) => {
      state.multiplierStatus = action.payload
    },
    setTokenExpiresDate: (state, action: PayloadAction<string>) => {
      state.tokenExpiresDate = action.payload
    },
    setTokenExpiresTime: (state, action: PayloadAction<string>) => {
      state.tokenExpiresTime = action.payload
    },
    setTokenExpiresDays: (state, action: PayloadAction<number>) => {
      state.tokenExpiresDays = action.payload
    },
    setDurationDays: (state, action: PayloadAction<number>) => {
      state.durationDays = action.payload
    },
    setMultiplier: (state, action: PayloadAction<number>) => {
      state.multiplier = action.payload
    },
    setMultiplierError: (state, action: PayloadAction<boolean>) => {
      state.multiplierError = action.payload
    },
    setMultiplierButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.multiplierButtonDisabled = action.payload
    },
    setUniqueMultiplierExists: (state, action: PayloadAction<boolean>) => {
      state.uniqueMultiplierExists = action.payload
    },
    setUniqueMultiplierActivated: (state, action: PayloadAction<boolean>) => {
      state.uniqueMultiplierActivated = action.payload
    },
    setUniqueMultiplierExpired: (state, action: PayloadAction<boolean>) => {
      state.uniqueMultiplierExpired = action.payload
    },
    setLoadingMultiplier: (state, action: PayloadAction<boolean>) => {
      state.loadingMultiplier = action.payload
    },
    setDecimalMultiplier: (state, action: PayloadAction<boolean>) => {
      state.decimalMultiplier = action.payload
    },
  },
})

export const {
  setMultiplierStatus,
  setTokenExpiresDate,
  setTokenExpiresTime,
  setTokenExpiresDays,
  setDurationDays,
  setMultiplier,
  setMultiplierError,
  setMultiplierButtonDisabled,
  setUniqueMultiplierExists,
  setUniqueMultiplierActivated,
  setUniqueMultiplierExpired,
  setLoadingMultiplier,
  setDecimalMultiplier,
} = multiplierSlice.actions
