import { Grid, Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'

const DetailThree = () => {
  const { durationDays } = useSelector((state: RootState) => state.multiplier)

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ display: { xxs: 'none', xs: 'flex' }, justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}
      >
        <Box
          sx={{
            background: '#6851AA 0% 0% no-repeat padding-box',
            borderRadius: '12px',
            opacity: 1,
            width: { xxs: '90%', sm: '540px', md: '600px' },
            paddingTop: { xs: '12px', sm: '0px' },
            paddingBottom: { xs: '12px', sm: '0px' },
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: { xxs: '3.2rem', sm: '5.5rem' },
                  fontWeight: 900,
                  color: '#F39AFF',
                }}
              >
                {durationDays}
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: { xxs: '1.1rem', xs: '1.3rem', sm: '1.6rem' },
                  fontWeight: 900,
                  color: '#F39AFF',
                  textTransform: 'uppercase',
                  marginTop: { xs: '-25px', sm: '-35px' },
                  paddingBottom: '15px',
                }}
              >
                {durationDays === 1 ? 'DAY' : 'DAYS'}
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography
                sx={{
                  textAlign: { xxs: 'center', xs: 'left' },
                  fontSize: { xs: '1.3rem', sm: '1.6rem' },
                  color: '#46FFF8',
                  fontWeight: 900,
                  lineHeight: 1.1,
                  paddingRight: { xs: '10px' },
                }}
              >
                Your multiplier runs for {durationDays} days from activation
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: { xxs: 'flex', xs: 'none' }, justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}
      >
        <Box
          sx={{
            background: '#6851AA 0% 0% no-repeat padding-box',
            borderRadius: '12px',
            opacity: 1,
            width: { xxs: '90%' },
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: { xxs: '1.1rem', xs: '1.3rem', sm: '1.6rem' },
              color: '#46FFF8',
              fontWeight: 900,
              lineHeight: 1.1,
              paddingRight: { xs: '10px' },
            }}
          >
            Your multiplier runs for {durationDays} days from activation
          </Typography>
        </Box>
      </Grid>
    </>
  )
}

export default DetailThree
