import { Grid, Typography } from '@mui/material'

const Title = () => {
  return (
    <Grid item xs={12} sx={{ paddingTop: '40px' }}>
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: 900,
          fontSize: { xxs: '30px', md: '50px' },
          letterSpacing: '0px',
          color: '#EE6FFF',
          opacity: 1,
          textTransform: 'uppercase',
        }}
      >
        How it works
      </Typography>
    </Grid>
  )
}

export default Title
