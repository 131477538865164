import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'

const Description = () => {
  const { multiplier, durationDays } = useSelector((state: RootState) => state.multiplier)

  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: 500,
          fontSize: { xxs: '0.8rem', xs: '1rem', md: '1.2rem' },
          letterSpacing: '0px',
          color: '#FFFFFF',
          opacity: 1,
          paddingLeft: { xxs: '5%', sm: '105px' },
          paddingRight: { xxs: '5%', sm: '105px' },
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        You’ve been exclusively selected to receive a Bling Point {multiplier}x multiplier for {durationDays} days. Activate below
        before your offer expires!
      </Typography>
    </Grid>
  )
}

export default Description
