import { Button } from '@mui/material'

const LatestCashOutButton = (props: any) => {
  return (
    <Button
      color="primary"
      variant="contained"
      type="submit"
      disabled={true}
      sx={{
        width: { xs: '50%', sm: '300px' },
        background: '#0467d7',
        borderRadius: '10px',
        color: 'white',
        '&.Mui-disabled': {
          color: 'gray',
        },
        marginTop: '10px',
        fontWeight: 600,
      }}
    >
      NEXT CASH OUT IN <br></br> {props.timer}
    </Button>
  )
}

export default LatestCashOutButton
