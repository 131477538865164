/**
 * Displays the success message after successfully cashing out (before Coinbase processes)
 */
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import Loader from '../../components/CashOut/Loader/Loader'
import SuccessMessage from '../../components/CashOut/Success/SuccessMessage'
import SuccessTitle from '../../components/CashOut/Success/SuccessTitle'
import MessageBox from '../../components/CashOut/Templates/Box/MessageBox'
import BottomLayout from '../../layouts/CashOut/BottomLayout'
import Layout from '../../layouts/CashOut/Layout'
import TopBanner from './TopBanner'

const Success = () => {
  const { loadingCashOutConfig } = useSelector((state: RootState) => state.cashOut)

  return (
    <Layout>
      <TopBanner />
      <BottomLayout paddingTop="100px">
        {!loadingCashOutConfig ? (
          <MessageBox>
            <SuccessTitle />
            <SuccessMessage />
          </MessageBox>
        ) : (
          <Loader height="35vh" />
        )}
      </BottomLayout>
    </Layout>
  )
}

export default Success
